<div class="wrapper static_wrapper bg4" id="operating-support">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">Támogatás</h1>
          <div>Ügyfélszolgálatunk munkaidőben UTC+01:00 időszerint 8.00-16.00-ig elérhető.<br>
            Elérhetőségeink:<br>
            ÁSZF: <a href="https:// www.inmemori.io/ASZF">https://www.inmemori.io/ASZF</a><br>
            E-mailen: <a href="mailto:help@inmemori.io">help@inmemori.io</a> max. 72 órán belüli válasz idővel.<br>
            Telefonon: +36 1 633 3733 Viber: +36 70 30 86 107</div>
        </div>

        <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-life-ring" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="wrapper static_wrapper" id="operating-foundation">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">Alapítás</h1>
          <div>InmemorI Virtual Time Caps Centruma Európában, Magyarországon, Szombathelyen bejegyzett InmemorI VTC World KFT. Az InmemorI Virtual Time Caps Centrum látja el a világon létrejött képviseletek, franchise partnerek koordinálását, továbbá az európai diszrtibúciókat. Szervezeti Centrumunkat felügyeli az InmemorI Virtual Time Caps szerzői jogaival rendelkező elnök. Az ő feladata és fő célkitűzése, valós emlékhely, időcapsula biztosítása minden ember és a jövő kor számára. A centrumot két működési vezérigazgató irányítja a következő igazgatóságokkal: Gazdasági Igazgatóság, Kereskedelmi Igazgatóság, Kommunikációs és Referensi Igazgatóság, Jogi Igazgatóság, Server Informatikai Háttért Biztosító Igazgatóság, Fejlesztési Igazgatóság. Ezen igazgatóságok vezetői hű elkötelezettjei azon eszmének, hogy az InmemorI Virtual Time Caps lehetőséget kell hogy adjon az emlékeink megőrzésére és annak  fenntarthatóságára. Minden munkatársunk azon fáradozik, hogy ezen elmélet teljesen kibővíthető legyen a való világ részére.Terveink között szerepel hogy bizonyos társadalmi temetkezési szokásokat is átalakítsunk, és arra törekedjünk, hogy a jövő generációja példaként tekinthessen az élettől elbúcsúzott elődeinkre az InmemorI Virtual Time Caps segítségével. Egyúttal létrehoztuk  az InmemorI Virtual temetőt az “InmemorI QR kód Plakett” segítségével.
          </div>

        <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-code-for" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>

