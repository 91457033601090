<div class="wrapper footer_wrapper">
    <div class="container">
        <footer>
            <div class="quarter">
              <img src="/assets/gfx/footer/img_inemory_logo.png" class="footer_inemory" />
            </div>
            <div class="quarter">
                <h3><span>Közösség</span></h3>
                <ul>
                    <li><a href="#" routerLink="community" fragment="community-about-us" l10nTranslate>aboutUs.title</a></li>
                    <li><a href="#" routerLink="community" fragment="community-about-sympaty" l10nTranslate>aboutSympaty.title</a></li>
                    <li><a href="#" routerLink="community" fragment="community-partyTicket" l10nTranslate>partyTicket.title</a></li>
                    <li><a href="#" routerLink="community" fragment="community-about-qr" l10nTranslate>aboutQrPlaque.title</a></li>
                </ul>
            </div>

            <div class="quarter">
                <h3><span>Cégről</span></h3>
                <ul>
                    <li><a href="#" routerLink="about-firm" fragment="about-firm-how" l10nTranslate>howDoesItWork.title</a></li>
                    <li><a href="#" routerLink="about-firm"  fragment="about-firm-care" l10nTranslate>care.title</a></li>
                    <li><a href="#" routerLink="operating" fragment="operating-support" l10nTranslate>support.title</a></li>
                </ul>
            </div>

            <div class="quarter">
                <h3><span>Működtető</span></h3>
                <ul>
                    <li><a href="#" routerLink="operating" fragment="operating-foundation"  l10nTranslate>foundation.title</a></li>
                    <li><a href="/files/terms-and-conditions.pdf" l10nTranslate>termsOfUse.title</a></li>
                    <li><a href="/files/privacy-policy.pdf" l10nTranslate>privacypolicy.title</a></li>
                    <li><a href="/files/prices.pdf" l10nTranslate>servicesprice.title</a></li>
                    <li><a href="/files/email_templates.pdf" l10nTranslate>emailtemplates.title</a></li>
                </ul>
            </div>
        </footer>
    </div>
</div>
