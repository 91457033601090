
        <div class="slider">
            <div class="sliderArrows">
                <!--
                <a (click)="backWard()"><</a>
                <a (click)="forWard()">></a>
                -->
            </div>
            <ul class="slideShow">
                <li *ngFor="let li of slides" [ngStyle]="{'display':li?.hidden?'none':''}" [ngClass]="li?.classes">
                    <printSlide [meta]="li"></printSlide>
                </li>
            </ul>
        </div>
    