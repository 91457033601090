
<div class="wrapper minheight">
  <div class="container">
    <div class="title_line">
      <h1 class="page_title" l10nTranslate>favorites.title</h1>
    </div>

    <div class="result_content" style="padding-bottom: 25px;">
      <div class="capsule_list">
        <div *ngFor="let item of favourites | async;" class="capsule_item">
          <div class="capsule"><app-capsule [capsule]="item" ></app-capsule></div>
        </div>

          <app-error-message [message]="'noItemFound'" *ngIf="(favourites | async)?.length == 0"></app-error-message>
      </div>
    </div>
    </div>
</div>
