<div class="fancy_overlay">
  <div class="fancy_bg">
    <div class="fancy_container" [class.smaller]="size === 'small'">
      <div class="fancy_header">
        <span class="close" (click)="closeModal($event)"><i class="icofont icofont-ui-close"></i></span>
      </div>
      <div class="fancy_content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
