<div class="wrapper static_wrapper" id="community-about-us">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">InmemorI Virtual Time Caps</h1>
          <div>Az InmemorI Virtuális Time Caps egy virtuális Emlékhely/kegyeleti hely, melyben a szerettünk emlékhelyét alapítjuk meg. Az elhunyt tevékenységeit, életrajzát megfogalmazzuk, (családfáját 1 lépcsőig felfelé és 1 lépcsőig lefelé automatikusan bemutatjuk később). Életeseményeit fotókkal, videókkal, hangfelvételekkel a jövő részére a virtuális capsulába helyezzük, melyet az elhalálozott hozzátartozója hozhat létre. InmemorI Virtual Time Caps-ot, az adott állam által kiadott  halotti anyakönyvi kivonat száma alapján, és a szerettünk neve alapján rögzíthetünk, vásárolhatunk meg.</div>
        </div>

        <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-map-marker" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="wrapper static_wrapper bg3" id="community-about-sympaty">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">“Sympaty” Üzenet</h1>
          <div>Az InmemorI lehetőséget biztosít minden regisztrált és azonosított felhasználónak, hogy a létező InmemorI Virtual Time Caps részére “Sympaty”-t küldjön. A “Sympaty” célja: friss elhalálozás esetén részvétnyilvánítás, mely örökre megmarad a szerettünk InmemorI Virtual Time Capsulájában, ellentétben a drága koszorúkkal és virágokkal . Rég elment hozzátartozóinknak, barátainknak, ismerőseinknek “Sympaty” küldésével jelezhetjük, hogy az ő élete a mi életünk része volt, és büszkék vagyunk arra, hogy őt ismerhettük. “Sympaty” küldésével a capsulába elhelyezzük az elhunyttal kapcsolatos emlékeinket, gondolatainkat, idézeteinket és fotóinkat. A “Sympaty”-k az adott Inemmori Virtual Time Capsban publikusan csak a gondozó jóváhagyásával jelennek meg (kegyeletsértés kizárása okából)!
          </div>
        </div>

        <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-comments-o" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="wrapper static_wrapper odd" id="community-partyTicket">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">Gyászjelentés</h1>
          <div>A “Gyászjelentés” célja, tájékoztatni az elmúlás bekövetkeztéről a rokonokat, ismerősöket, barátokat. Elektronikus világunkban a nyomtatott papírt felváltja az InmemorI Virual Time Caps Gyászjelentése, melyet a gondozó (capsula alapítója) által meghatározott e-mail címekre továbbít az InmemorI. A gyászjelentés tartalmazza a haláleset beálltát, a búcsúztató helyszínét és időpontját, és egyben tájékoztatja a címzettet, hogy mint a capsula gondozója, létrehozta a szerette részére az InmemorI Virtual Time Caps-t. Tájékoztatást kap a címzett a capsula QR kódjáról (Caps linkjéről), illetve arról, hogy ide küldheti a hozzátartozó a Sympaty-t, az elhervadó virágok és koszorúk helyett.</div>
        </div>

        <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-address-card" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="wrapper static_wrapper bg1" id="community-about-qr">
  <div class="container">
    <div class="content_holder">
      <div class="row">
        <div class="three_quarter">
          <h1 class="page_title">QR-plakett</h1>
          <div>Az InmemorI QR Plakett célja, hogy létrehozzuk a virtuális temetőt. A plakettet a capsula gondozója rendelheti meg, akár a capsula létrehozásakor vagy az után. Az InmemorI QR kód Plakett divíziója 240 órán belül postázza a capsula gondozója részére az UV bevonattal rendelkező 60x120 mm, ezüst színű, műanyag plakettet gravírozva, 3M ragasztócsíkkal ellátva. A “QR Plakett” tartalmazza az elhunyt  nevét, születési és elhalálozási évét, QR kódját. A gondozó ezen “QR  Plakettet” elhelyezheti a sírkertben lévő fejfára, sírkőre. A sírkertet látogató ismerős a mobil eszközén lévő QR kód olvasó segítségével  azonnal tájékoztatást kap az elhunytról, azaz a capsula tartalmáról (regisztrálás nélkül is!).
          </div>
        </div>


          <div class="quarter">
          <span class="static_icon">
            <i class="fa fa-qrcode" _ngcontent-c2="" aria-hidden="true"></i>
          </span>
          </div>
        </div>

      </div>
    </div>
