<div class="title_line">
    <div class="right_side title">
        <h1 l10nTranslate>placeSearch.title</h1>
    </div>
</div>

<div class="left_side search_form">
    <form class="searchform">
        <div class="add_place">
            <h2 l10nTranslate>placeSearch.addPlace</h2>
            <p l10nTranslate>placeSearch.description</p>
            <label>
                <input id="autocomplete" ng2-google-place-autocomplete #cityName (setAddress)="onAddCity($event)"
                       l10n-placeholder
                       l10nTranslate
                       placeholder="placeSearch.addGoogleSearch"
                       [options]="{types: ['(cities)']}"/>
            </label>
        </div>

        <div class="place_list">
            <div *ngFor=" let item of (cityService.cities | async)" (click)="onCityClick(item)" class="place_item">
                <img [src]="item.photoRef" class="place_item_image"/>
                <a href="javascript:void(0)" class="place_item_close" title="Törlés" (click)="onRemoveCity(item)"><i
                        class="icofont icofont-close-line-circled"></i></a>
                <div class="place_item_info">
                    <h3>{{ item.name }}</h3>
                </div>
            </div>
        </div>
    </form>
</div>


<div class="right_side result_content">
    <div class="capsule_list location">

        <!--<div *ngFor="let item of (capsules | async)" class="capsule_item">{{item.firstName}}</div>-->
        <div *ngFor="let item of (capsules | async)?.content" class="capsule_item">
            <div class="capsule">
                <app-capsule [capsule]="item" [showDistance]="false"></app-capsule>
            </div>
        </div>

        <div class="noContent" *ngIf="(capsules | async)?.content.length == 0"><p l10nTranslate>
            search.noResultsFound</p></div>
    </div>
</div>




