<app-modal [size]="'small'">
    <div class="sympathies">
        <h1 l10nTranslate> sympathyMessages.title</h1>
        <div class="scrollable">
            <div *ngIf="!(userService.isLoggedIn | async)" l10nTranslate>logInToUseThisFeature</div>
            <app-error-message [message]="'noItemFound'" *ngIf="(sympathies | async)?.length == 0"></app-error-message>

            <div *ngIf="userService.isLoggedIn | async">

                <div *ngFor="let message of sympathies | async" class="sympathyes_item">
                    <div *ngIf="message.imagePath != null" class="sympathyes_image"
                         [appImageBackground]="message.imagePath">
                        <!--<img [appFileImage]="message.imagePath">-->
                        <img src="/assets/gfx/dummy.png"/>
                    </div>

                    <div class="sympathyes_message_content">
                        <select (change)="onChange(message.id, $event)" class="sympathyes_status_serect"
                                [ngModel]="message.status">
                            <option l10nTranslate value="APPROVED">ACCEPTED</option>
                            <option l10nTranslate value="REJECTED">REJECTED</option>
                            <option l10nTranslate value="PENDING">PENDING</option>
                        </select>
                        <div class="sympathyes_message_title"><strong><span l10nTranslate>capsule</span>: <a
                                [routerLink]="['', { outlets: { modal: ['capsules', message.capsuleId] } }]">{{message.capsuleFirstName}}
                            {{message.capsuleLastName}}</a></strong></div>
                        <div class="sympathyes_message_text">
                            <p>{{message.message}}</p>
                        </div>
                        <div class="sympathyes_author">
                            <p>{{message.userName}}</p><br>
                            <p class="datatime">{{message.created | date:'yyyy-MM-dd HH:mm'}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</app-modal>
