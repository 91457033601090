<div class="scrollable">
  <div class="personal_infos">
      <div class="section_title"><i class="icofont icofont-file-jpg"></i></div>
      <!--<p>{{(capsule | async)?.photos.length}} Fotók</p>-->
    <p l10nTranslate>files.photos</p>


      <div class="image_gallery">
          <div class="gallery_item" *ngFor="let item of (capsule | async)?.photos">
              <div class="image_holder" [appImageBackground]="item">
                  <!--<a><img [appFileImage]="item" (click)="openImageModel(item)"/></a>-->
                  <a><img src="/assets/gfx/dummy.png" (click)="openImageModel(item)"></a>

                  <a *ngIf="(isMyCapsule | async)" type="button" value="delete" (click)="onPictureClick(item)"
                     class="delete_item"><i class="icofont icofont-trash"></i></a>
              </div>
          </div>
        <div class="upload_add_item" *ngIf="(isMyCapsule | async) && (capsule | async)?.photos.length < 12">
          <label>
            <i class="fa fa-plus-square" aria-hidden="true"></i>
            <input type="file" id="imageuploadinput" (change)="onUploadPhoto($event.target.files.length > 0 ? $event.target.files[0] : null)"
                   placeholder="Upload"
                   accept=".jpg,.jpeg,.png"
                   #submitPicture/>
          </label>
        </div>


          <div *ngIf="openModalWindow">
              <modal-gallery [modalImages]="fullImagePhotos | async"
                             enableCloseOutside="true"
                             [imagePointer]="imagePointer"
                             (close)="onCloseImageModal($event)"></modal-gallery>
          </div>

      </div>


      <div class="section_title"><i class="icofont icofont-file-text"></i></div>
      <p>{{(capsule | async)?.documents.length}} dokumentum</p>
      <div class="sympathy_files">
          <div class="item" *ngFor="let item of (capsule | async)?.documents">
              <a [href]="item" target="_blank"><i class="icofont icofont-file"></i> Capsule Document</a>
              <a *ngIf="(isMyCapsule | async)" (click)="onDeleteDoc(item)" class="delete_sympathy_file"><i
                      class="icofont icofont-trash"></i></a>
          </div>
          <div class="document_upload item" *ngIf="(isMyCapsule | async)">
            <label>
              <i class="fa fa-plus-square" aria-hidden="true"></i> <span l10nTranslate>capsuleDetail.addNewDocument</span>
              <input type="file" id="fileuploadinput" (change)="onUploadDoc($event.target.files.length > 0 ? $event.target.files[0] : null)"
                     placeholder="Upload"
                     accept=".pdf,.doc,.docx,.xls,.zip,.xlsx,.jpg,.jpeg,.png"/>
            </label>
          </div>
      </div>

      <div class="section_title"><i class="icofont icofont-folder-search"></i></div>
      <p l10nTranslate>capsuleDetail.sympathyPhotos</p>
      <div class="image_gallery">
          <!--<div class="gallery_item" *ngFor="let item of (capsule | async)?.sympathies">-->
          <div class="gallery_item" *ngFor="let item of messages">
              <div class="image_holder" [appImageBackground]="item.imagePath">
                <a><img src="/assets/gfx/dummy.png" (click)="openSympathyImageModel(item)"></a>
                  <!--<a><img [appFileImage]="item.imagePath" (click)="openSympathyImageModel(item)"/></a>-->

              </div>
          </div>
          <div *ngIf="openSympathiModalWindow">
              <modal-gallery [modalImages]="sympathyphotos"
                             enableCloseOutside="true"
                             [imagePointer]="imagePointer"
                             (close)="onCloseImageModal($event)"></modal-gallery>
          </div>
      </div>
  </div>
</div>
