<div class="scrollable">

    <div class="personal_infos">

        <div *ngIf="!(userService.isLoggedIn | async)" l10nTranslate>logInToUseThisFeature</div>
        <div *ngIf="(userService.isLoggedIn | async)">

            <p l10nTranslate *ngIf="(userService.isLoggedIn | async) && (hasSufficientFunds | async)">
                sympathyMessages.template.information</p>

            <div class="nothenughpoint" *ngIf="(userService.isLoggedIn | async) && !(hasSufficientFunds | async)"><span
                    l10nTranslate>sympathyMessages.buySympathy</span>
                <a [routerLink]="['', {outlets: { modal2: 'purchase' } }]" l10nTranslate class="btn">sympathyMessages.buySympathy2</a>
            </div>

            <div class="section_title"><i class="ico_cirkledingz">2</i></div>

            <div class="badges_icons">
                <div class="badges_icon_holder" *ngFor="let item of (pictureTemplates | async)">
                    <div class="badges_icon">
                        <img *ngIf="item.imagePath!=null" (click)="sendChoosedTemplate(item)"
                             [appFileImage]="item.imagePath" id="image-{{item.imagePath}}"/>
                    </div>
                </div>
            </div>

            <div class="section_title"><i class="icofont icofont-quote-left"></i></div>
            <p>Idézetek</p>

            <div class="quotes">
                <div class="quotes_item" *ngFor="let item of (messageTemplates | async)">
                    <a id="template-{{item.id}}" href="javascript:void(0)" (click)="sendChoosedTemplate(item)">
                        {{item.message}} </a>
                </div>
            </div>
        </div>
    </div>
</div>
