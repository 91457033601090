<div class="wrapper">
    <div class="container">
        <div class="red_bg">
            <h1 class="page_title" l10nTranslate>newCapsule.title<br>
                <del>{{ (capsuleItem | async)?.fullPrice }} {{ (capsuleItem | async)?.currency }}</del>
                {{(capsuleItem | async)?.price }} {{ (capsuleItem | async)?.currency }}
            </h1>
            <div class="white_bg">
                <div class="html_edited">
                    <div [hidden]="(loggedIn | async)" l10nTranslate>logInToUseThisFeature</div>

                    <div [hidden]="!loaded">
                        <div [hidden]="!(loggedIn | async) || (!(hasSalesPermission | async) && (hasUnusedCapsule | async))">
                            <form class="form">
                                <div class="half">
                                    <p l10nTranslate>
                                        newCapsule.prepare
                                    </p>
                                    <label l10nTranslate>newCapsule.paymentMethod <select required name="paymentMethod"
                                                                                          [(ngModel)]="dto.paymentType"
                                                                                          #paymentTypeSelect>
                                        <option value="CREDIT_CARD" l10nTranslate>CREDIT_CARD</option>
                                        <option value="CASH" *ngIf="hasHigherPrivilege | async" l10nTranslate>CASH
                                        </option>
                                    </select></label>
                                </div>
                                <div class="half">
                                    <p><br><br></p>
                                    <p>
                                        <a *ngIf="( ((showShopButton | async) == 1) && ( dto.ownerId != '' ) )"
                                           [queryParams]="{enabledShopType: 'CAPSULE_CREATION', 'ownerId': dto.ownerId }"
                                           [routerLink]="['', { outlets: { modal2: 'purchase' } } ]"
                                           l10nTranslate class="btn"> newCapsule.buyWithCreditCard</a>
                                    </p>
                                    <br class="clear"/>
                                    <p *ngIf="hasSalesPermission | async"><a class="btn" style="width: 250px;"
                                                                             routerLink="/register" l10nTranslate>newCapsule.registerUser</a><br>
                                    </p>
                                </div>
                            </form>
                            <p l10nTranslate [hidden]="(showForm | async) != 0">
                                newCapsule.information
                            </p>
                        </div>
                    </div>
                    <br class="clear"/>
                    <form class="form" #f="ngForm" (submit)="onCreateCapsule()" [hidden]="(showForm | async) == 0" (keydown.enter)="$event.preventDefault()">
                        <div class="half">
                            <div *ngIf="hasSalesPermission | async">
                                <label l10nTranslate>capsule.owner <span>*</span>
                                    <app-user-autocomplete (user)="dto.ownerId = $event.id"></app-user-autocomplete>
                                </label>

                            </div>
                        </div>

                        <br class="clear"/>

                        <div class="half">
                            <label l10nTranslate>capsule.firstName <span>*</span><input required type="text"
                                                                                        name="firstName"
                                                                                        [(ngModel)]="dto.firstName"/></label>

                            <label l10nTranslate>capsule.lastName <span>*</span><input required type="text"
                                                                                       name="lastName"
                                                                                       [(ngModel)]="dto.lastName"/></label>

                            <label l10nTranslate>capsule.maidenName <input type="text" name="maidenName"
                                                                           [(ngModel)]="dto.maidenName"/></label>

                            <label l10nTranslate>capsule.birthPlace <span>*</span><input required
                                                                                         id="autocompleteBirthLocation"
                                                                                         ng2-google-place-autocomplete
                                                                                         #birthPlace
                                                                                         (setAddress)="onChooseBirthCity(birthPlace.value, $event)"/></label>


                            <label l10nTranslate>capsule.dateOfBirth <span>*</span>
                                <div class="col-xs-12 col-12 col-md-4 form-group">


                                    <input type="date"
                                           required
                                           class="form-control"
                                           [(ngModel)]="dto.dob"
                                           name="birthDate">
                                </div>
                            </label>


                            <label l10nTranslate>capsule.placeOfDeath <span>*</span><input required
                                                                                           id="autocompleteDeathLocation"
                                                                                           ng2-google-place-autocomplete
                                                                                           #deathLocationName
                                                                                           (setAddress)="onChooseDeathCity(deathLocationName.value, $event)"/></label>
                            <label l10nTranslate>capsule.dateOfDeath <span>*</span>
                                <div class="col-xs-12 col-12 col-md-4 form-group">

                                    <input type="date"
                                           required
                                           class="form-control"
                                           [(ngModel)]="dto.dod"
                                           name="dod">
                                </div>
                            </label>


                            <label l10nTranslate>capsule.cemetery <span>*</span><input id="autocomplete"
                                                                                       ng2-google-place-autocomplete
                                                                                       #cemetery
                                                                                       required
                                                                                       (setAddress)="onChooseGraveyardCity(cemetery.value, $event)"
                                                                                        (street_number) = "cemetery.street_number=$event"
                                                                                        (street)= "cemetery.street=$event"
                                                                                        (city)= "cemetery.city=$event"
                                                                                        (vicinity)= "cemetery.vicinity=$event"
                                                                                        (country)="cemetery.country=$event"
                                                                                        (postal_code)="cemetery.postal_code=$event"
                                                                                        (name)="cemetery.name=$event"/>
                            </label>

                            <label><p class="input_note"><em l10nTranslate>newCapsule.cemeteryNote</em></p>
                            </label>


                            <label l10nTranslate>capsule.funeralDate <span>*</span>
                                <div class="col-xs-12 col-12 col-md-4 form-group">
                                    <input type="datetime-local"
                                           class="form-control"
                                           required
                                           #dp
                                           [(ngModel)]="dto.funeralDate"
                                           name="funeralDate">
                                </div>
                            </label>

                            <label><p class="input_note"><em l10nTranslate>newCapsule.funeralDateInfo</em></p>
                            </label>


                            <label l10nTranslate>capsule.motherName <span>*</span><input type="text"
                                                                                         required
                                                                                         name="motherName"
                                                                                         [(ngModel)]="dto.motherName"></label>

                            <label l10nTranslate>capsule.fatherName <span>*</span><input type="text" name="fatherName"
                                                                                         required
                                                                                         [(ngModel)]="dto.fatherName"></label>

                            <label l10nTranslate>capsule.siblingsName <input type="text" name="siblingsName"
                                                                             [(ngModel)]="dto.siblingsName"></label>
                        </div>
                        <div class="half">
                            <label class="double" l10nTranslate><span class="double">capsule.photo</span><span
                                    *ngIf="!(hasSalesPermission | async)">*</span> <input
                                    type="file" id="fileuploadinput2"
                                    [(ngModel)]="_photoFake"
                                    name="photo"
                                    [required]="!(hasSalesPermission | async)"
                                    (change)="photo = $event.target.files.length > 0 ? $event.target.files[0] : null;"
                                    placeholder="Upload"
                                    accept=".jpg,.jpeg,.png,.gif"/></label>

                            <label l10nTranslate>capsule.homePlace <input id="autocompleteHomeLocation"
                                                                          ng2-google-place-autocomplete
                                                                          #homeLocation
                                                                          (setAddress)="onChooseHomeCity(homeLocation.value, $event)"/></label>

                            <label l10nTranslate>capsule.education <input type="text" name="education"
                                                                          [(ngModel)]="dto.education"/></label>

                            <label l10nTranslate>capsule.job <input type="text" name="job"
                                                                    [(ngModel)]="dto.job"/></label>

                            <label l10nTranslate>capsule.school <input type="text" name="school"
                                                                       [(ngModel)]="dto.school"/></label>

                            <label l10nTranslate>capsule.workPlace<input type="text" name="workPlace"
                                                                         [(ngModel)]="dto.workPlace"/></label>

                            <label l10nTranslate *ngIf="hasSalesPermission | async">capsule.authorityName <span>*</span><input
                                    type="text"
                                    name="authorityName"
                                    [(ngModel)]="dto.authorityName"/></label>

                            <label [hidden]="!(dto.paymentType === 'CASH')" l10nTranslate>capsule.proofOfPayment
                                <span>*</span><input
                                        type="text"
                                        name="proofOfPayment"
                                        [required]="(dto.paymentType === 'CASH')"
                                        [(ngModel)]="dto.proofOfPayment"/></label>

                            <label l10nTranslate *ngIf="!(hasSalesPermission | async)" class="double"><span
                                    class="double">capsule.deathNumber
                                <span>*</span></span><input
                                    type="text" name="deathNumber"
                                    required
                                    [(ngModel)]="dto.deathNumber"/></label>

                            <label l10nTranslate *ngIf="!(hasSalesPermission | async)" class="double"><span
                                    class="double">capsule.deathCert
                                <span>*</span></span><input
                                    type="file" id="fileuploadinput"
                                    [(ngModel)]="_deadCertFileFake"
                                    name="deadCert"
                                    (change)="deadCertFile = $event.target.files.length > 0 ? $event.target.files[0] : null;"
                                    placeholder="Upload"
                                    accept=".pdf,.doc,.docx,.xls,.zip,.xlsx,.jpg,.jpeg,.png"/></label>

                            <label l10nTranslate>capsule.career
                                <ckeditor
                                        name="description"
                                        (ready)="onCkEditorReady($event)"
                                        [(ngModel)]="dto.career"
                                        debounce="500"
                                        required
                                        [config]="{uiColor: '#FFFFFF', height: 600 , extraPlugins: 'divarea', toolbar: [{name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']}, {name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']}, {name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']}, {name: 'styles', items: ['Styles', 'Format']}]}"></ckeditor>
                            </label>
                        </div>

                        <input type="submit" name="submit" l10nTranslate #submitButton l10n-value l10nTranslate
                               value="newCapsule.save"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

