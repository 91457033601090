<div class="wrapper">
    <div class="container">
        <div class="red_bg">
            <h1 class="page_title" l10nTranslate>soldCapsules.title</h1>
            <div class="white_bg">
                <div class="admin">
                  <div class="admin_filter">
                    <div class="quarter">

                      <label l10nTranslate>employee.fromDate <span>*</span>
                          <input type="text"
                                 class="form-control"
                                 [(ngModel)]="fromDate"
                                 [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                 #dp="bsDatepicker"
                                 bsDatepicker/>
                      </label>
                    </div>
                    <div class="quarter">
                      <label l10nTranslate>employee.toDate <span>*</span>
                          <input type="text"
                                 class="form-control"
                                 #dp="bsDatepicker"
                                 [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                 [(ngModel)]="toDate"
                                 bsDatepicker/>
                      </label>
                    </div>
                    <div class="quarter">
                      <input type="button" l10nTranslate="value" style="margin-bottom: 0;" #searchButton value="search"/>
                    </div>
                    <br class="clear" /><br>

                    <table class="admin_table">
                        <thead>
                        <tr>
                            <th l10nTranslate>soldCapsules.capsuleName</th>
                            <th l10nTranslate>soldCapsules.buyerName</th>
                            <th l10nTranslate>soldCapsules.sales</th>
                            <th l10nTranslate>soldCapsules.soldDate</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr name="pager"
                            *ngFor="let item of (capsules | async)">
                            <td><a [routerLink]="['', { outlets :{modal: ['capsules' , item.id]}}]">{{item.firstName}}
                                {{item.lastName}} </a></td>
                            <td>{{item.ownerName}}</td>
                            <td>{{item.salesName}} ({{item.salesEmail}})</td>
                            <td>{{item.created | date: 'yyyy-MM-dd'}}</td>
                        </tr>
                        </tbody>
                    </table>

                    <strong l10nTranslate>search.allResults</strong> <span>{{ (capsules | async)?.length }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
