<app-modal>
    <div id="login-box">
        <div class="login_form">
            <div class="login_content">
                <form (ngSubmit)="onSubmit()" #f="ngForm">
                    <input required type="text" l10n-placeholder placeholder="login.emailAddress" l10nTranslate
                           name="email"
                           [(ngModel)]="email"/>
                    <input required type="password" l10n-placeholder placeholder="login.password" name="password"
                           l10nTranslate
                           [(ngModel)]="password"/>
                    <input type="submit" [disabled]="!f.valid" name="submit" l10n-value value="login.loginButton"
                           l10nTranslate/>
                    <div class="lost_password">
                        <a href="javascript:void(0)" name="lostpassword" (click)="onLostPasswordClick()" l10nTranslate>
                            login.forgotPassword
                        </a>
                    </div>
                </form>

            </div>
            <div class="register">
                <a class="btn_reg" href="#"
                   [routerLink]="['', { outlets: { primary: 'register', modal: null, modal2: null } }]" ><span class="register_outlines" l10nTranslate>login.newHere</span> <br>
                    <span
                            l10nTranslate>login.joinNow</span></a>
            </div>
        </div>
    </div>
</app-modal>
