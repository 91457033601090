<div class="header_tools">

    <ul class="user_infos" *ngIf="!(simplifiedHeader | async)">
        <li><a
                [routerLink]="[{ outlets: { primary: 'community', modal: null, modal2: null } }]"
                fragment="community-about-sympaty"
                routerLinkActive="current"
                [routerLinkActiveOptions]="{exact: true}"
                title="aboutSympaty.title"
                l10nTranslate
                l10n-title><span><i
                class="fa fa-commenting"
                aria-hidden="true"></i></span></a>
        </li>

        <li><a
                [routerLink]="[{ outlets: { primary: 'community', modal: null, modal2: null } }]"
                fragment="community-about-qr"
                routerLinkActive="current"
                [routerLinkActiveOptions]="{exact: true}"
                title="aboutQrPlaque.title"
                l10nTranslate
                l10n-title><span><i
                class="fa fa-qrcode"
                aria-hidden="true"></i></span></a>
        </li>

    </ul>


    <!--<a class="search_button" title="Keresés"><i class="icofont icofont-search"></i></a>-->
    <div class="profile_picture" *ngIf="userService.isLoggedIn | async"><a><img
            [placeholder]="'/assets/gfx/default_270.jpeg'"
            [appFileImage]="(user | async)?.photoPath" (click)="onProfileClick()"/>
    </a></div>

    <div *ngIf="!(userService.isLoggedIn | async)" class="lock_holder">
        <a id="login-trigger" class="red" href="javascript:void(0)" (click)="onLoginClick()">
            <i class="fa fa-lock" aria-hidden="true"></i>
        </a>
    </div>

    <div class="user_menu" [hidden]="!showProfileOverflow">


        <div class="user_menu_name_part">
            <span class="user_name_part_first">{{ (userService.me | async)?.name }}</span>
            <span class="user_name_part_second">{{ (userService.me | async)?.email }}</span>
        </div>

        <div class="user_menu_item_part">

            <div class="menu_item">
                <a class="userMenuItem" l10nTranslate
                   [routerLink]="[{ outlets: { primary: 'profile', modal: null, modal2: null } }]">
                    <div class="icofontBackground">
                        <i class="icofont icofont-user-alt-3"></i></div>
                    <div style="float: left; margin: 20px auto 20px 10px;">profileMenu.profile</div>
                </a>
            </div>

            <div class="menu_item" *ngIf="showCompanyButton | async">
                <div class="show_admin_part">
                    <a class="userMenuItem" l10nTranslate
                       [routerLink]="['/companies',(userService.me | async)?.id]">
                        <div class="icofontBackground">
                            <i class=" icofont icofont-contact-add">
                            </i>
                        </div>
                        <div style="float: left; margin: 20px auto;margin-left: 10px">profileMenu.company</div>
                    </a>
                </div>
            </div>

            <!-- <div class="menu_item">
               <div class="userMenuItem">
                 <a l10nTranslate
                    [routerLink]="['', {outlets: {modal2: 'purchase'}}]">
                   <div class="icofontBackground">
                     <i class="icofont icofont-credit-card"></i>
                   </div>
                   <div style="float: left; margin: 20px auto;margin-left: 10px">profileMenu.shop</div>
                 </a>
               </div>
             </div>-->

            <div class="menu_item">
                <div *ngIf="showAdminButton | async" class="show_admin_part">
                    <div class="userMenuItem">
                        <a l10nTranslate
                           [routerLink]="[{ outlets: { primary: 'admin', modal: null, modal2: null } }]">
                            <div class="icofontBackground">
                                <i class="icofont icofont-contact-add"></i>
                            </div>
                            <div style="float: left; margin: 20px auto;margin-left: 10px">profileMenu.adminPage</div>
                        </a>
                    </div>
                </div>
            </div>


            <div class="logout_holder">
                <a class="logut_btn" l10nTranslate (click)="onLogout()">profileMenu.logout</a>
            </div>
        </div>
    </div>
</div>
