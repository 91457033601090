<span (clickOutside)="closeMenu()">
    <button (click)="menuToggle()" class="hamburger hamburger-arrow-left" [ngClass]="{'is-active': menuState, '': !menuState}">
        <span class="icon"></span>
    </button>
<div class="menu-container cuppa-menu" [ngClass]="{'show-menu': menuState, 'hide-menu': !menuState}">
    <ul>
        <li *ngFor="let item of menulist" (click)="onItemClick(item)">
            <a (click)="toggleSubMenu(item)">
                <i [ngClass]="item.icon" style="float: left; margin-right: 10px;"></i>
                {{item.title}}
                <i *ngIf="item.subItems && !item.expand" class="fa fa-angle-down"></i>
                <i *ngIf="item.subItems && item.expand" class="fa fa-angle-up"></i>
            </a>
           <ul *ngIf="item.subItems" class="sub-menu" [hidden]="!item.expand">
               <li *ngFor="let subitem of item.subItems" (click)="onItemClick(subitem)"><a>{{subitem.title}}</a></li>
           </ul>
        </li>
    </ul>
</div>
<span>
<!--<div class="cuppa-menu-overlay" [ngClass]="{'show-overlay': menuState, 'hide-overlay': !menuState}"></div> -->