<div class="personal_messages">
    <div class="scrollable">
        <div class="personal_infos">
            <div>
                <div class="section_title"><i class="icofont icofont-social-google-talk"></i></div>
                <p>InmemorI Virtual Time Caps Sympaty</p>

                <div l10nTranslate l10n-innerHTML innerHTML="inmemori.firstDesc" class="html_edited" style="color: black;"></div>
                <div l10nTranslate l10n-innerHTML innerHTML="inmemori.warningMessage" class="html_edited" style="color: black;"></div>
            </div>
            <p>
                <br>
            </p>

            <div style="position: relative; clear:both; left: 0; right: 0">
                <div class="section_title"><i class="icofont icofont-quote-left"></i></div>
                <p>Idézetek</p>

                <div class="html_edited" l10nTranslate>
                    sympathyMessages.templateDescription
                </div>

                <div class="quotes">
                    <div class="quotes_item" *ngFor="let item of (messageTemplates | async)">
                        <a id="template-{{item.id}}" href="javascript:void(0)" (click)="chooseTemplate(item)">
                            {{item.message}} </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="message_line" [hidden]="!(userService.isLoggedIn | async) || !(hasSufficientFunds | async)">
        <img *ngIf="file!=null" [appImagePreview]="file" height="100" class="miniatur"/>
        <label for="fileuploadinput">
            <input _ngcontent-c15="" accept=".jpg,.jpeg,.png,.gif" id="fileuploadinput" (change)="fileChange($event)"
                   l10nTranslate l10n-placeholder
                   placeholder="sympathyMessages.uploadPicture" type="file"/>
            <i class="icofont icofont-plus-circle" title="sympathyMessages.uploadPicture" l10nTranslate l10n-title></i>
        </label>
        <input type="text" [(ngModel)]="message" #textField/>
        <!--<select (change)="onSelectedTemplateChange($event.target.value)">
            <option value="" l10nTranslate>messageShop.selectTemplate</option>
            <option *ngFor="let item of (messageTemplates | async)">{{ item.message }}</option>
        </select>-->
        <input type="submit" value="&#xf1d8;" #submitButton/>
    </div>

    <div *ngIf="!(userService.isLoggedIn | async)" style="float: left; width: 100%; text-align: center; padding-top: 30px;">
        <h2 l10nTranslate>sympathyMessages.logInToSend</h2>
    </div>

    <div class="nothenughpoint" *ngIf="(userService.isLoggedIn | async) && !(hasSufficientFunds | async)" style="float: left; width: 100%; text-align: center; padding-top: 30px;"><h2
            l10nTranslate>sympathyMessages.buySympathy</h2>
        <a [queryParams]="{enabledShopType: 'SYMPATHY_GIVING'}"
           [routerLink]="['', {outlets: { modal2: 'purchase' } }]" l10nTranslate class="btn">sympathyMessages.buySympathy2</a>
    </div>
</div>