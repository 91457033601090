
<div class="wrapper static_wrapper">
  <div class="container">
    <div class="content_holder">

      <div class="three_quarter">
        <h1 class="page_title" l10nTranslate>{{title}}</h1>
        <div [innerHTML]="localeKeyword" l10n-innerHTML l10nTranslate></div>
      </div>

      <div class="quarter">
        <span class="static_icon">
          <i class="fa fa-qrcode" _ngcontent-c2="" aria-hidden="true"></i>
        </span>
      </div>
    </div>

    </div>
  </div>
