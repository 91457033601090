<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <div class="white_bg">
        <div class="admin">
          <div class="admin_filter">
            <div class="quarter">
              <label l10nTranslate>employee.fromDate <span>*</span>
                <input type="text"
                       class="form-control"
                       [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                       [(ngModel)]="fromDate"
                       #dp="bsDatepicker"
                       bsDatepicker/>
              </label>
            </div>
            <div class="quarter">
            <label l10nTranslate>employee.toDate <span>*</span>
              <input type="text"
                     class="form-control"
                     #dp="bsDatepicker"
                     [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                     [(ngModel)]="toDate"
                     bsDatepicker/>
            </label>
            </div>
            <div class="quarter">
              <select name="type" #typeControl>
                <option value="" l10nTranslate>all</option>
                <option *ngFor="let item of (transactionStates)" value="{{item}}" l10nTranslate>qrStatus.{{ item }}
                  <!-- </option>-->
              </select>
            </div>
            <div class="quarter">
              <input type="button" l10nTranslate="value" #searchButton value="search"/>
            </div>
          </div>
          <table class="admin_table">
            <thead>
            <tr>
              <th l10nTranslate>qrPlaques.customer</th>
              <th l10nTranslate>qrPlaques.capsuleName</th>
              <th l10nTranslate>qrPlaques.birthName</th>
              <th l10nTranslate>qrPlaques.dobDod</th>
              <th l10nTranslate>qrPlaques.deliveryAddress</th>
              <th l10nTranslate>qrPlaques.contactEmail</th>
              <th l10nTranslate>qrPlaques.contactPhone</th>
              <th l10nTranslate>qrPlaques.status</th>
              <th l10nTranslate>qrPlaques.orderDate</th>
              <th l10nTranslate>qrPlaques.postedDate</th>

            </tr>
            </thead>
            <tbody>
            <tr name="pager"
                *ngFor="let item of (qrList)">
              <td>{{item.ownerName}} ({{item.ownerId}})</td>
              <td>
                <a [routerLink]="['', { outlets :{modal: ['capsules' , item.capsule.id]}}]">
                  {{item.capsule.firstName}} {{item.capsule.lastName}}</a></td>
              <td>{{ item.capsule.maidenName }}</td>
              <td>{{item.capsule.dob | date: 'yyyy'}} - {{item.capsule.dod | date: 'yyyy'}}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.contactEmail }}</td>
              <td>{{ item.contactPhone }}</td>
              <td><select [(ngModel)]="item.status" (change)="statusChanged(item)" name="type">
                <!--<option value="" l10nTranslate>all</option>-->
                <option *ngFor="let item of (transactionStates)" value="{{item}}" l10nTranslate>qrStatus.{{ item }}
                  <!-- </option>-->
              </select></td>
              <td>{{ item.created | date: 'yyyy-MM-dd' }}</td>

              <td>{{ item.status == 'APPROVED' ? (item.updated | date: 'yyyy-MM-dd') : '-' }}</td>

            </tr>
            </tbody>
            <!--                        <tr>
                                        <td colspan="6">
                                            <pagination-controls #paginationControl
                                                                 previousLabel="Előző"
                                                                 nextLabel="Következő"></pagination-controls>
                                        </td>
                                    </tr>-->
          </table>

        </div>
      </div>
    </div>
  </div>
</div>
