<div class="wrapper">
    <div class="container">
        <div class="red_bg">
            <h1 class="page_title" l10nTranslate>registration.title <span l10nTranslate>registration.subtitle</span>
            </h1>
            <div class="white_bg">
                <form (ngSubmit)="onRegister($event)" class="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">
                    <div class="half">
                        <label l10nTranslate>registration.name <span>*</span><input type="text" name="name"
                                                                                    l10n-placeholder
                                                                                    l10nTranslate
                                                                                    placeholder="registration.fullName"
                                                                                    [(ngModel)]="registerDto.name"
                                                                                    required></label>

                        <label l10nTranslate>registration.email <span>*</span><input type="email" name="email"
                                                                                     [(ngModel)]="registerDto.email"
                                                                                     l10n-placeholder
                                                                                     l10nTranslate
                                                                                     placeholder="registration.fullEmail"
                                                                                     email
                                                                                     required></label>
                        <!--
                                    <label l10nTranslate>registration.countryCode <span>*</span><input type="text" name="phone"
                                                                                                       [(ngModel)]="registerDto.countryCode"
                                                                                                       l10n-placeholder
                                                                                                       l10nTranslate
                                                                                                       placeholder="registration.countryCodeExplanation"

                                                                                                       required></label>-->

                        <label l10nTranslate>registration.phone <span>*</span><input type="text" name="phone"
                                                                                     [(ngModel)]="registerDto.phone"
                                                                                     l10n-placeholder
                                                                                     l10nTranslate
                                                                                     placeholder="registration.fullPhone"

                                                                                     required></label>

                        <label l10nTranslate>registration.birthPlace <span>*</span><input type="text" name="birthPlace"
                                                                                          id="autocompleteBirthPlace"
                                                                                          l10n-placeholder
                                                                                          l10nTranslate
                                                                                          placeholder="registration.fullPlaceOfbirth"
                                                                                          ng2-google-place-autocomplete
                                                                                          (setAddress)="registerDto.birthPlace = $event.name"
                                                                                          required></label>

                        <!--    <label l10nTranslate>registration.dateOfBirth <span>*</span><input type="date" min="1900-01-01"
                                                                                               max="2099-12-31" name="dob"
                                                                                               l10n-placeholder
                                                                                               l10nTranslate
                                                                                               placeholder="registration.fullDateOfBirth"
                                                                                               [(ngModel)]="registerDto.dob"
                                                                                               required></label>-->
                        <label l10nTranslate>registration.dateOfBirth <span>*</span>
                            <div class="col-xs-12 col-12 col-md-4 form-group">
                                <input type="text"
                                       class="form-control"
                                       #dp="bsDatepicker"
                                       [(ngModel)]="registerDto.dob"
                                       [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                       name="birthDate"
                                       bsDatepicker>
                            </div>
                        </label>

                        <label l10nTranslate>registration.gender <span>*</span>
                            <select required name="gender" [(ngModel)]="registerDto.gender">
                                <option value="MALE" l10nTranslate>gender.male</option>
                                <option value="FEMALE" l10nTranslate>gender.female</option>
                            </select>
                        </label>
                    </div>
                    <div class="half">
                        <label l10nTranslate>registration.motherName <span>*</span><input type="text" name="motherName"
                                                                                          [(ngModel)]="registerDto.motherName"
                                                                                          l10n-placeholder
                                                                                          l10nTranslate
                                                                                          placeholder="registration.fullMotherName"
                                                                                          required></label>

                        <label l10nTranslate *ngIf="registerDto.gender == 'FEMALE'">registration.maidenName
                            <span>*</span><input
                                    type="text" name="maidenName" [(ngModel)]="registerDto.maidenName" required></label>

                        <label l10nTranslate>registration.homePlace <span>*</span><input type="text" name="homePlace"
                                                                                         id="autocompleteHomeLocation"
                                                                                         ng2-google-place-autocomplete
                                                                                         l10n-placeholder
                                                                                         l10nTranslate
                                                                                         placeholder="registration.fullPlaceOfbirth"
                                                                                         (setAddress)="registerDto.homePlace = $event.name"
                                                                                         required></label>

                        <label><p class="input_note"><em l10nTranslate>registration.homePlaceDescription</em></p>
                        </label>

                        <label l10nTranslate>registration.photo <span
                                *ngIf="!(hasHigherPrivilege | async)">*</span><input
                                type="file" id="fileuploadinput"
                                [(ngModel)]="_photoFake"
                                (change)="photo = $event.target.files.length > 0 ? $event.target.files[0] : null;"
                                name="photo"
                                placeholder="registration.fullProfile"
                                [required]="!(hasHigherPrivilege | async)"
                                accept=".jpg,.jpeg,.png,.gif"/></label>

                        <label l10nTranslate>registration.password <span>*</span><input type="password" name="password"
                                                                                        [(ngModel)]="registerDto.password"
                                                                                        minlength="6"
                                                                                        l10n-placeholder
                                                                                        l10nTranslate
                                                                                        placeholder="registration.firstPassword"
                                                                                        required></label>

                        <label><p class="input_note"><em l10nTranslate>registration.passwordirequiredinfo</em></p>
                        </label>

                        <label l10nTranslate>registration.password2 <span>*</span><input type="password"
                                                                                         name="password2"
                                                                                         l10n-placeholder
                                                                                         l10nTranslate
                                                                                         [(ngModel)]="password2"
                                                                                         placeholder="registration.repeatPassword"
                                                                                         minlength="6"
                                                                                         required></label>

                        <p *ngIf="registerDto.password != password2" l10nTranslate>registration.passwordMismatch</p>

                        <p></p>

                        <small>(<span l10nTranslate>registration.activateEmailText</span>)</small>

                        <input type="submit" name="submit" class="btn fright" l10n-value l10nTranslate
                               value="registration.registerButton"
                               [disabled]="!f.valid || registerDto.password != password2">
                    </div>
                </form>

                <div class="html_edited">
                    <p> </p>
                    <p l10nTranslate>registration.average.warning <span><a
                            href='/files/terms-and-conditions.pdf'>(ÁSZF)</a></span></p>
                    <p l10nTranslate>registration.average.warning2</p>
                    <p l10nTranslate>registration.average.warning3 <span><small>(Inmemori World)</small></span></p>
                </div>
            </div>
        </div>
    </div>
</div>

