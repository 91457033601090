<div class="scrollable">
    <form class="details_form" #informationForm="ngForm">
      <div style="float: left; width: 100%; padding: 0 15px;">
        <label><input type="button" style="border: 1px solid #000; height: auto; font-size: 12px;" name="capsuleCreationEmail" *ngIf="showEmailButtons | async" l10nTranslate
                      #submitButton (click)="onSendCapsuleCreation()"
                      value="capsule.capsuleCreationEmail"></label>

        <label><input type="button" style="border: 1px solid #000; font-size: 12px;" name="capsulePartyEmail" *ngIf="showEmailButtons | async" l10nTranslate
                      #submitButton (click)="onSendPartyEmail()"
                      value="capsule.capsulePartyEmail"></label>
      </div>

        <div class="personal_infos">

            <div class="section_title"><i class="fa fa-street-view" aria-hidden="true"></i></div>

            <table class="data_table">
                <tr>
                    <th l10nTranslate>capsule.firstName</th>
                    <th l10nTranslate>capsule.lastName</th>
                </tr>
                <tr>
                    <td>
                        {{ (capsule | async)?.firstName }}
                    </td>
                    <td>
                        {{ (capsule | async)?.lastName }}
                    </td>
                </tr>
                <tr *ngIf="(capsule | async)?.maidenName?.length > 0">
                    <th l10nTranslate>capsule.maidenName</th>
                </tr>
                <tr>
                    <td>
                        {{ (capsule | async)?.maidenName }}
                    </td>
                </tr>
                <tr>
                    <th l10nTranslate>capsuleDetail.birthInfo</th>
                    <th l10nTranslate>capsuleDetail.deathInfo</th>
                </tr>
                <tr>
                    <td>
                        {{ (capsule | async)?.birthLocation }}
                    </td>
                    <td>
                        {{ (capsule | async)?.deathLocation }}
                    </td>
                </tr>

                <tr>
                    <td>
                        {{ (capsule | async)?.dob }}
                    </td>
                    <td>
                        {{ (capsule | async)?.dod }}
                    </td>
                </tr>
            </table>

            <table class="data_table">
                <tr>
                    <th l10nTranslate>capsule.motherName</th>
                    <th l10nTranslate>capsule.fatherName</th>
                </tr>
                <tr>
                    <td>
                        {{ (capsule | async)?.motherName }}
                    </td>
                    <td>
                        {{ (capsule | async)?.fatherName }}
                    </td>
                </tr>
                <tr>
                    <th l10nTranslate>capsule.siblingsName</th>
                    <th l10nTranslate>capsule.owner</th>
                </tr>
                <tr>
                    <td>
                        <inline-editor type="text" [disabled]="!(isMyCapsule | async)"
                                       [ngClass]="!(isMyCapsule | async) ? 'disabled' : 'enabled'"
                                       [ngModel]="(capsule | async)?.siblingsName" name="siblingsName"></inline-editor>
                    </td>

                    <td>
                        {{(capsule | async)?.ownerName}}
                    </td>
                </tr>
            </table>

            <div class="section_title"><i class="fa fa-home" aria-hidden="true"></i></div>

            <table class="data_table">
                <tr>
                    <th l10nTranslate>capsule.homePlace</th>
                    <th l10nTranslate>capsule.cemetery</th>
                </tr>
                <tr>
                    <td>
                        {{ (capsule | async)?.homePlace }}
                    </td>
                    <td>
                        {{(capsule | async)?.graveyard.name}}
                        <!--          <label><input required id="autocomplete" *ngIf="(isMyCapsule | async)"
                                                ng2-google-place-autocomplete
                                                name="graveyard"
                                                #cityName
                                                [ngModel]="(capsule | async)?.graveyard.name"
                                                placeholder=""
                                                (setAddress)="onChooseGraveyardCity($event)"/></label>-->
                    </td>
                <tr>
                    <th></th>
                    <th l10nTranslate>capsule.funeralDate</th>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        {{ (capsule | async)?.funeralDate | date:'yyyy-MM-dd' }}
                    </td>
                </tr>
            </table>

            <div class="section_title"><i class="fa fa-child" aria-hidden="true"></i></div>

            <table class="data_table">
                <tr>
                    <th l10nTranslate>capsule.job</th>
                    <th l10nTranslate>capsule.workPlace</th>
                </tr>
                <tr>
                    <td>
                        <inline-editor type="text" [disabled]="!(isMyCapsule | async)"
                                       [ngClass]="!(isMyCapsule | async) ? 'disabled' : 'enabled'"
                                       [ngModel]="(capsule | async)?.job"
                                       name="job"></inline-editor>
                    </td>
                    <td>
                        <inline-editor type="text" [disabled]="!(isMyCapsule | async)"
                                       [ngClass]="!(isMyCapsule | async) ? 'disabled' : 'enabled'"
                                       [ngModel]="(capsule | async)?.workPlace" name="workPlace"></inline-editor>
                    </td>
                </tr>


                <tr>
                    <th l10nTranslate>capsule.education</th>
                    <th l10nTranslate>capsule.school</th>
                </tr>
                <tr>
                    <td>
                        <inline-editor type="text" [disabled]="!(isMyCapsule | async)"
                                       [ngClass]="!(isMyCapsule | async) ? 'disabled' : 'enabled'"
                                       [ngModel]="(capsule | async)?.education" name="education"></inline-editor>
                    </td>
                    <td>
                        <inline-editor type="text" [disabled]="!(isMyCapsule | async)"
                                       [ngClass]="!(isMyCapsule | async) ? 'disabled' : 'enabled'"
                                       [ngModel]="(capsule | async)?.school" name="school"></inline-editor>
                    </td>
                </tr>
            </table>


            <div class="section_title"><i class="fa fa-line-chart" aria-hidden="true"></i></div>
            <div class="html_edited">
                <span *ngIf="!(isMyCapsule | async)" [innerHTML]="(capsule | async)?.career"></span>
                <p l10nTranslate >capsule.onlyCareer</p>
                <ckeditor
                        name="career"
                        (ready)="onCkEditorReady($event)"
                        [ngModel]="(capsule | async)?.career"
                        *ngIf="(isMyCapsule | async)"
                        debounce="500"
                        [config]="{uiColor: '#FFFFFF', height: 600 , extraPlugins: 'divarea', toolbar: [{name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']}, {name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']}, {name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']}, {name: 'styles', items: ['Styles', 'Format']}]}">
                </ckeditor>
            </div>


            <label><input type="submit" name="submit" l10nTranslate [hidden]="!(isMyCapsule | async)" #submitButton
                          (click)="onSaveClick()"
                          value="capsule.save"></label>

        </div>

    </form>
</div>

