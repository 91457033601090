<div class="wrapper">
    <div class="container">
        <div class="red_bg">
            <div class="white_bg">
                <div class="admin">
                    <div class="half type_select">
                      <label class="admin_user_search" l10nTranslate>type
                          <select name="type" #typeControl>
                              <!--<option value="" l10nTranslate>all</option>-->
                              <option *ngFor="let item of (transactionStates)" value="{{item}}" l10nTranslate>{{ item }}
                              </option>
                          </select>
                      </label>
                    </div>
                    <div class="half type_select">
                      <label class="admin_user_search" l10nTranslate>transaction.paymentType
                          <select name="payment_type" #paymentTypeControl>
                              <option value="" l10nTranslate>all</option>
                              <option *ngFor="let item of (paymentTypes)" value="{{item}}" l10nTranslate>{{ item }}</option>
                          </select>
                      </label>
                    </div>

                    <table class="admin_table">
                        <thead>
                        <tr>
                            <th l10nTranslate>transaction.id</th>
                            <th l10nTranslate>transaction.buyerName</th>
                            <th l10nTranslate>transaction.type</th>
                            <th l10nTranslate>transaction.price</th>
                            <th l10nTranslate>transaction.paymentType</th>
                            <th l10nTranslate>transaction.status</th>
                            <th l10nTranslate>transaction.date</th>
                            <th l10nTranslate>transaction.lastModifiedDate</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr name="pager"
                            *ngFor="let item of transactions?.content | paginate: { itemsPerPage: transactions?.size, currentPage: page | async, totalItems: transactions?.totalElements}">
                            <td>{{item.id}}</td>
                            <td>{{item.user.name}} (<a href="mailto: {{item.user.email}}">{{item.user.email}}</a>) <br>{{item.user.homePlace}}</td>
                            <td l10nTranslate>{{item.shopItemType}}</td>
                            <td>{{item.price}} {{item.currency}}</td>
                            <td><span l10nTranslate>{{item.type}}</span> {{item.proofOfPayment}}</td>
                            <td>
                                <select [(ngModel)]="item.status" (change)="statusChanged(item)">
                                    <option *ngFor="let status of (transactionStates)" value="{{status}}" l10nTranslate>{{ status }}
                                    </option>
                                </select>
                            </td>
                            <td>{{item.created | date: 'yyyy-MM-dd'}}</td>
                            <td>{{item.status == 'BILLED' ? (item.updated | date: 'yyyy-MM-dd') : '-'}} <br/>{{item.status == 'BILLED' ? (item.modifier) : '-'}}</td>
                        </tr>
                        </tbody>
                        <tr>
                            <td colspan="8">
                                <pagination-controls #paginationControl
                                                     previousLabel="Előző"
                                                     nextLabel="Következő"></pagination-controls>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
