<app-modal>
    <div class="data_sheet">
        <div class="half left_bg">
            <div class="profile_image_holder" [appImageBackground]="(capsule | async)?.photoPath">
                <div class="qr">
                    <qr-code [value]="qrUrl | async" [size]="150"></qr-code>
                </div>

                <div *ngIf="isMyCapsule | async">
                    <div class="buyCapsule" *ngIf="!(capsule | async)?.qrTransparentAlreadyPurchased">
                        <a (click)="onBuyQr()" href="javascript:void(0)" l10nTranslate class="btn">capsuleDetail.buyQrPlaque</a>
                    </div>

                    <div class="buyCapsule"
                         *ngIf="(capsule | async)?.qrTransparentAlreadyPurchased && !(capsule | async)?.qrTransparentAlreadyPosted">
                        <span l10nTranslate>capsuleDetail.qrTransparentAlreadyPurchased</span>
                    </div>
                </div>

                <div class="buyCapsule" *ngIf="(capsule | async)?.qrTransparentAlreadyPosted">
                    <span l10nTranslate>capsuleDetail.qrTransparentAlreadyPosted</span>
                </div>

                <div class="upload_edit_profile_picture" *ngIf="(isMyCapsule | async)">
                    <label>
                        <i class="icofont icofont-pencil-alt-2" l10nTranslate l10n-title
                           title="capsule.changeProfilePicture"></i>
                        <input type="file" id="imageuploadinput"
                               (change)="onUploadPhoto($event.target.files.length > 0 ? $event.target.files[0] : null)"
                               placeholder="Upload"
                               accept=".jpg,.jpeg,.png"/>
                    </label>
                </div>

                <img src="/assets/gfx/dummy.png">
                <!--<img [appFileImage]="(capsule | async)?.photoPath" class="profile_image"/>-->
                <div class="data_sheet_text">
                    <h1>{{(capsule | async)?.firstName}} {{(capsule | async)?.lastName}}</h1>
                    <p class="year">
                        <span>{{(capsule | async)?.dob | date:'yyyy'}} - {{(capsule | async)?.dod | date:'yyyy'}}</span>
                    </p>
                    <p> {{(capsule | async)?.graveyard.city}}</p>
                </div>

                <div class="capsule_navigation">
                    <ul class="capsule_menu">
                        <li>
                            <a fragment="capsule-content"
                               [routerLink]="['', {outlets: { modal: 'capsules\/' + (capsule | async)?.id + '\/info' } }]"
                               routerLinkActive="current" l10n-title l10nTranslate title="capsule.information"><i
                                    class="fa fa-indent" aria-hidden="true"></i></a></li>
                        <li>
                            <a fragment="capsule-content"
                               [routerLink]="['', {outlets: { modal: 'capsules\/' + (capsule | async)?.id + '\/files' } }]"
                               routerLinkActive="current" l10n-title l10nTranslate title="capsule.files"><i
                                    class="icofont icofont-folder-open"></i></a>
                        </li>

                        <li>
                            <a fragment="capsule-content"
                               [routerLink]="['', {outlets: { modal: 'capsules\/' + (capsule | async)?.id + '\/messages'} }]"
                               routerLinkActive="current" l10n-title l10nTranslate title="capsule.sendSympathy"><i
                                    class="fa fa-commenting"
                                    aria-hidden="true"></i></a>
                        </li>

                        <li>
                            <a fragment="capsule-content"
                               [routerLink]="['', {outlets: { modal: 'capsules\/' + (capsule | async)?.id + '\/messageShop'} }]"
                               routerLinkActive="current" l10n-title l10nTranslate title="send.sympathy"><i
                                    class="icofont icofont-social-google-talk"
                                    aria-hidden="true"></i></a>
                        </li>

                        <li>
                            <!--<a [routerLink]="['', {outlets: { modal: 'capsules\/' + (capsule | async)?.id + '\/templates' } }]"
                               routerLinkActive="current" l10n-title l10nTranslate title="capsule.simpathyTemplate"><i
                                    class="ico_cirkledingz">2</i></a>-->

                            <a class="disabled" l10n-title l10nTranslate title="capsule.simpathyTemplate"><i
                                    class="ico_cirkledingz">2</i></a>
                        </li>
                        <li><a class="disabled"><i class="fa fa-sitemap" aria-hidden="true"></i></a></li>
                    </ul>
                </div>


            </div>


            <div class="more_images">
                <div *ngFor="let item of mixedPhotos | async; let i = index" class="more_image_item"
                     [appImageBackground]="item.img">
                    <a *ngIf="i<5" (click)="openImageModel(item)"><img src="/assets/gfx/dummy.png"></a>
                </div>
            </div>

            <div *ngIf="openModalWindow">
                <modal-gallery [modalImages]="photos"
                               enableCloseOutside="true"
                               [imagePointer]="imagePointer"
                               (close)="onCloseImageModal($event)"></modal-gallery>
            </div>

        </div>
        <div id="capsule-content" class="half right_bg">

            <router-outlet #outlet (activate)="componentAdded($event)"
                           (deactivate)="componentRemoved($event)"></router-outlet>
        </div>


    </div>


    <!--
      <table>
          <tr>
              <td colspan="3"></td>
              <td>
                  <table>
                      <tr><input type="button" [routerLink]="['', {outlets: { modal: ['capsules', capsule | async)?.id] }]" value="Information"></tr>
                      <tr><input type="button" [routerLink]="['', {outlets: { modal: ['capsules', (capsule | async)?.id, 'messages']} }]" value="Messages"></tr>
                      <tr><input type="button" [routerLink]="['', {outlets: { modal: ['capsules', (capsule | async)?.id, 'files'] } }]" value="Files"></tr>
                      <tr><input type="button" [routerLink]="['', {outlets: { modal: ['capsules', (capsule | async)?.id, 'templates'] } }]" value="Templates"></tr>
                      <tr><input type="button" value="????"></tr>
                  </table>
              </td>
          </tr>
          <tr>
              <td colspan="4"><p>{{(capsule | async)?.firstName}} {{(capsule | async)?.lastName}}</p></td>
          </tr>
          <tr>
              <td colspan="4"><p>{{(capsule | async)?.dob | date:'yyyy'}} - {{(capsule | async)?.dod |
                  date:'yyyy'}}</p>
              </td>
          </tr>
          <tr>
              <td colspan="4"><p> {{(capsule | async)?.deathLocation}}</p></td>
          </tr>
          <tr>
              <td *ngFor="let item of (capsule | async)?.photos; let i = index">
                  <img *ngIf="i<4" [appFileImage]="item" width="150" height="150">
              </td>
          </tr>
      </table>


  -->


</app-modal>
