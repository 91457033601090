<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title">Adminisztráció</h1>
      <div class="white_bg">
        <div class="admin_mainmenu">
          <div class="item"><a l10n-value l10nTranslate name="user.list" (click)="onUsersClick()"><i class="fa fa-users"
                                                                                                     aria-hidden="true"></i>
            <br> user.list</a></div>

          <div class="item"><a l10n-value l10nTranslate name="capsules.list" routerLink="/admin/capsules"><i
            class="fa fa-users" aria-hidden="true"></i>
            <br> capsules.list</a></div>

          <div class="item"><a l10n-value l10nTranslate name="company.list" routerLink="/admin/companies" class="admin"><i
            class="fa fa-id-card-o" aria-hidden="true"></i>
            <br> company.list</a></div>
          <div class="item"><a l10n-value l10nTranslate name="capsules.list" routerLink="/admin/transactions"><i
            class="fa fa-users" aria-hidden="true"></i>
            <br> transaction.list</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
