<app-modal modalOutletName="modal2">
    <div class="webshop">
        <div class="webshop_inner">

            <h2 l10nTranslate>profileMenu.shop</h2>
            <div class="html_edited">
                <ul>
                    <li *ngFor="let item of (itemToDisplay | async)" l10nTranslate>
                        shop.{{item.type}}
                    </li>
                </ul>
            </div>
            <p></p>
            <div class="pricing">
                <div class="price-option price-option--low" *ngFor="let item of (itemToDisplay | async)">
                    <div>
                        <div class="price-option__detail">
                            <div class="first_line"><strong *ngIf="(inventoryCount(item.type) | async) > 0"><span
                                    l10nTranslate>shop.unusedItem</span>
                                ({{ (inventoryCount(item.type) | async) }} <span l10nTranslate>pieces</span>)</strong>
                            </div>
                            <i class="ico_cirkledingz">
                                <i *ngIf="(item.type === 'CAPSULE_CREATION')" class="icofont icofont-infinite"
                                   style="color: black;"></i>
                                <i *ngIf="(item.type === 'SYMPATHY_GIVING')" class="fa fa-commenting" aria-hidden="true"
                                   style="color: black;"></i>
                                <i *ngIf="(item.type === 'QR_TRANSPARENT')" class="fa fa-qrcode" aria-hidden="true"
                                   style="color: black;"></i>
                            </i>
                            <span class="price-option__type" l10nTranslate>  {{item.type}}</span>
                            <span class="price-option__cost"><del *ngIf="item.fullPrice != null"
                                                                  style="font-size: 19px;">{{item.fullPrice}} {{item.currency}}</del> {{item.price}} {{item.currency}}</span>
                        </div>
                        <a (click)="onItemClick(item)" href="javascript:void(0)" class="price-option__purchase"
                           l10nTranslate>profileMenu.shop</a>
                    </div>
                </div>
            </div>
            <div l10nTranslate l10n-innerHTML innerHTML="shop.descriptionafter" class="html_edited"></div>

            <p l10nTranslate class="small">
                <em> purchase.acceptRules </em>
            </p>
        </div>
    </div>
</app-modal>
