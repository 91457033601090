<form class="searchform" #searchForm="ngForm">
  <!--<h2 l10nTranslate>search.name</h2>-->

  <label><input type="text" ngModel name="name" l10n-placeholder l10nTranslate placeholder="search.name"/></label>
  <!--<h1> {{ (currentLocation | async)?.coords?.latitude }} {{ (currentLocation | async)?.coords?.longitude }} </h1>-->

  <!--  <label><input type="text" ngModel l10n-placeholder l10nTranslate name="firstName" placeholder="capsule.firstName"></label>
    <label><input type="text" ngModel name="lastName" l10n-placeholder l10nTranslate placeholder="capsule.lastName"></label>-->
  <label><input type="text" ngModel name="birthLocation" l10n-placeholder l10nTranslate
                placeholder="capsule.birthPlace"></label>
  <label><input type="text" ngModel name="deathLocation" l10n-placeholder l10nTranslate
                placeholder="capsule.placeOfDeath"></label>
  <label><input type="text" class="form-control datepicker" #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                ngModel name="dob" bsDatepicker l10n-placeholder l10nTranslate
                placeholder="capsule.dateOfBirth"></label>
  <!-- <label><input type="date" ngModel name="dob" min="1000-01-01" max="2099-01-01" l10n-placeholder l10nTranslate
                 placeholder="capsule.dateOfBirth"></label>-->
  <!--  <label><input type="date" ngModel name="dod" min="1000-01-01" max="2099-01-01" l10n-placeholder l10nTranslate
                  placeholder="capsule.dateOfDeath"></label>-->
  <label><input type="text" class="form-control datepicker" #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                ngModel name="dod" bsDatepicker l10n-placeholder l10nTranslate
                placeholder="capsule.dateOfDeath"></label>
  <label><input type="text" ngModel name="motherName" l10n-placeholder l10nTranslate
                placeholder="capsule.motherName"></label>
  <label><input type="text" ngModel name="fatherName" l10n-placeholder l10nTranslate
                placeholder="capsule.fatherName"></label>
  <label><input type="text" ngModel name="siblingsName" l10n-placeholder l10nTranslate
                placeholder="capsule.siblingsName"></label>
  <input type="submit" name="submit" l10n-value l10nTranslate value="search" #searchButton>
</form>
