<div class="title_line">
    <div class="right_side title">
        <h1 l10nTranslate>search.title</h1>
    </div>
</div>

<div class="left_side search_form">
    <app-capsule-search (results)="onResultChange($event)"></app-capsule-search>
</div>
<div class="right_side result_content">
    <isotope [options]="{ fitWidth: true, resize: true, initLayout: true }" #isotope>
        <div class="capsule_list">
            <div>
                <isotope-brick *ngFor="let item of results; let i = index" class="capsule_item">
                    <div class="capsule">
                        <app-capsule [capsule]="item"></app-capsule>
                    </div>
                </isotope-brick>

                <app-error-message
                        [message]="results?.length == 0 ? 'search.noResultsNearby' : null"></app-error-message>
                <app-error-message [message]="errorMessage"></app-error-message>
            </div>
        </div>
    </isotope>

    <div *ngIf="locationService.searchingLocation | async">
        <div class="spinner_frame">
            <spinner [size]="150" [tickness]="3" [color]="'#ffffff'"
                     *ngIf="locationService.searchingLocation | async"></spinner>
            <div class="html_edited white"><h1 l10nTranslate>search.obtainingLocation</h1></div>
        </div>
    </div>
</div>
