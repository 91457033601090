<div class="capsule_info">
  <a [routerLink]="['', { outlets: { 'modal': ['capsules', capsule.id] } }]">
    <div class="capsule_image_holder" [appImageBackground]="capsule.photoPath">
      <!--<img [appFileImage]="capsule.photoPath" class="capsule_image"/>-->
      <img src="/assets/gfx/dummy.png" class="capsule_image">
    </div>
    <div class="capsule_info_text">
      <h3>{{capsule.firstName}} {{capsule.lastName}}</h3>
      <p *ngIf="capsule.dob!=null">({{capsule.dob | date : 'yyyy'}}-{{capsule.dod | date: 'yyyy'}})
        {{capsule.graveyard.city}}</p>
      <p *ngIf="capsule.distanceInKm!=null && showDistance">{{capsule.distanceInKm | number : '1.2-2'}} km</p>
    </div>
  </a>
</div>
