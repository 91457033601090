<div class="personal_messages">

    <div class="felx_container" *ngIf="messages?.length == 0">
        <div class="flex_content">
            <h2 l10nTranslate>sympathy.noSympatyMessagesYet</h2>
        </div>
    </div>

  <div #scrollContainer class="personal_messages_scroll_container full_height" *ngIf="messages?.length != 0">
    <div class="personal_messages_holder">
      <div class="message_item" *ngFor="let item of (messages)">
        <div class="message_content">
          <div class="connected_image_holder" *ngIf="item.imagePath != null"
               [appImageBackground]="item.imagePath">
            <!--<img *ngIf="item.imagePath != null" [appFileImage]="item.imagePath" class="connected_image"/>-->
            <img src="/assets/gfx/dummy.png" class="connected_image"/>
          </div>
          <p>{{ item.message }}</p>
        </div>
        <div class="message_author">
          <div class="author_holder"><img [appFileImage]="item.userPhotoPath"
                                          placeholder="/assets/gfx/default_270.jpeg"/></div>
          <p>{{ item.userName }} <em>{{item.created | date:'yyyy/MM/dd HH:mm'}}</em></p>
        </div>
      </div>

    </div>
  </div>

<!--  <div class="message_line" [hidden]="!(userService.isLoggedIn | async) || !(hasSufficientFunds | async)">

    <img *ngIf="file!=null" [appImagePreview]="file" height="100" class="miniatur"/>
    <label for="fileuploadinput"><i class="icofont icofont-plus-circle"></i></label>
    <input type="file" id="fileuploadinput" (change)="fileChange($event)" placeholder="Upload"
           accept=".pdf,.doc,.docx,.xls,.zip,.xlsx,.jpg,.jpeg,.png"/> <input type="text" l10n-placeholder
                                                                             #textField name="message"
                                                                             [(ngModel)]="message">
    <input type="submit" name="submit" #submitButton value="&#xf1d8;">
  </div>-->
  <!--<div></div>-->

  <!--<p class="buy" *ngIf="!(hasSufficientFunds | async) && (userService.isLoggedIn | async)">
    <span l10nTranslate>sympathyMessages.buySympathy</span>
    <a l10nTranslate [queryParams]="{enabledShopType: 'SYMPATHY_GIVING'}" [routerLink]="['', {outlets: { modal2: 'purchase' }}]">sympathyMessages.buySympathy2</a>
  </p>-->

  <!--<div *ngIf="!(userService.isLoggedIn | async)"><a l10nTranslate class="link" href="/community(modal:login)">logInToUseThisFeature</a></div>-->
</div>

