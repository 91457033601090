
        <div *ngIf="meta.sType=='div'" [innerHTML]="meta.content">

        </div>
        <div *ngIf="meta.sType=='ajaxDiv'">
            <slideAjaxDiv [url]="meta.contentUrl"></slideAjaxDiv>
        </div>
        
        <div [style.background-image]="'url(' + meta.imgSrc + ')'"></div>
        <!-- <img [src]="meta.imgSrc" *ngIf="meta.sType=='img'"/> -->
    