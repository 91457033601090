<div class="wrapper minheight">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title" l10nTranslate>notFoundPage.title</h1>
      <div class="white_bg">
        <div class="html_edited">
          <p l10nTranslate>notFoundPage.notFound</p>
          <a class="btn fright" routerLink="/capsules" l10nTranslate>notFoundPage.backToHome</a>
        </div>
      </div>
    </div>
  </div>
</div>
