<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title" l10nTranslate>profileMenu.profile</h1>
      <div class="white_bg">
        <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSaveClick($event)" class="form" #f="ngForm" #informationForm="ngForm">
          <div class="half">
            <label l10nTranslate>registration.name <span>*</span><input type="text" name="name"
                                                                        [ngModel]="(me | async)?.name"
                                                                        (change)="registerDto.name = $event.target.value"
                                                                        required
            ></label>

            <label l10nTranslate>registration.email
              <input type="text" name="email" disabled [ngModel]="(me | async)?.email"/></label>

            <label l10nTranslate>registration.phone <span>*</span><input type="text" name="phone"
                                                                         [ngModel]="(me | async)?.phone"
                                                                         (change)="registerDto.phone = $event.target.value"
                                                                         required
            ></label>

            <label l10nTranslate>registration.birthPlace <span>*</span><input type="text" name="birthPlace"
                                                                              id="autocompleteBirthPlace"
                                                                              [ngModel]="(me | async)?.birthPlace"
                                                                              (setAddress)="registerDto.birthPlace = birthPlace.value"
                                                                              #birthPlace
                                                                              required
                                                                              ng2-google-place-autocomplete
            ></label>

          <!--  <label l10nTranslate>registration.dateOfBirth <span>*</span><input type="date" min="1900-01-01"
                                                                               max="2099-12-31" name="dob"
                                                                               [ngModel]="(me | async)?.dob"
                                                                               required
                                                                               (change)="registerDto.dob = $event.target.value"
            ></label>-->
            <label l10nTranslate>registration.dateOfBirth <span>*</span>
              <div class="col-xs-12 col-12 col-md-4 form-group">
                <input type="text"
                       class="form-control"
                       #dp="bsDatepicker"
                       [ngModel]="(me | async)?.dob"
                       [(ngModel)]="registerDto.dob"
                       name="birthDate"
                       bsDatepicker>
              </div>
            </label>



            <label l10nTranslate>registration.gender <span>*</span>
              <select name="gender" [ngModel]="(me | async)?.gender"
                      (change)="registerDto.gender = $event.target.value">
                <option value="MALE" l10nTranslate>gender.male</option>
                <option value="FEMALE" l10nTranslate>gender.female</option>
              </select>
            </label>
          </div>
          <div class="half">
            <label l10nTranslate>registration.motherName <span>*</span><input type="text" name="motherName"
                                                                              [ngModel]="(me | async)?.motherName"
                                                                              required
                                                                              (change)="registerDto.motherName = $event.target.value"
            ></label>

            <label l10nTranslate *ngIf="registerDto.gender == 'FEMALE'">registration.maidenName
              <span>*</span><input required type="text" name="maidenName" [ngModel]="(me | async)?.maidenName"
                                   (change)="registerDto.maidenName = $event.target.value"></label>

            <label l10nTranslate>registration.homePlace <span>*</span><input type="text" name="homePlace"
                                                                             id="autocompleteHomeLocation"
                                                                             ng2-google-place-autocomplete
                                                                             #homePlace
                                                                             required
                                                                             [ngModel]="(me | async)?.homePlace"
                                                                             (setAddress)="registerDto.homePlace = homePlace.value"
            ></label>

            <label><p class="input_note"><em l10nTranslate>registration.homePlaceDescription</em></p>
            </label>

            <label l10nTranslate>registration.photo
              <span>*</span><input
                type="file" id="fileuploadinput"
                (change)="photo = $event.target.files.length > 0 ? $event.target.files[0] : null;"
                required
                placeholder="Upload"
                accept=".jpg,.jpeg,.png,.gif"/></label>

            <label l10nTranslate>registration.password <input readonly onfocus="this.removeAttribute('readonly');"
                                                              type="password" name="password" autocomplete="false"
                                                              [(ngModel)]="registerDto.password"
                                                              minlength="6"
                                                              (change)="registerDto.password=registerDto.password?.length == 0 ? null : $event.target.value"></label>

            <label l10nTranslate>registration.password2 <input readonly onfocus="this.removeAttribute('readonly');"
                                                               type="password" autocomplete="false"
                                                               name="password2"
                                                               minlength="6"
                                                               (change)="password2=$event.target.value?.length == 0 ? null : $event.target.value">
            </label>

            <p *ngIf="registerDto.password != password2" l10nTranslate>registration.passwordMismatch</p>

            <input type="submit" name="submit" class="btn fright" l10n-value l10nTranslate #submitButton
                   value="registration.modifyButton"
                   [disabled]="!f.valid || registerDto.password != password2">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

