<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title">{{ (company | async)?.name }}</h1>
      <div class="white_bg">
        <div class="admin">
          <table class="admin_table">
            <tr>
              <td l10nTranslate>registration.name</td>
              <td>{{ (company | async)?.name }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.managerDetails</td>
              <td>{{ (company | async)?.manager.name}} ({{ (company | async)?.manager.email}})</td>
            </tr>
            <tr>
              <td l10nTranslate>company.representative</td>
              <td>{{ (company | async)?.representative}} ({{ (company | async)?.representativeEmail}})
              </td>
            </tr>
            <tr>
              <td l10nTranslate>employeeList.email</td>
              <td>{{ (company | async)?.email }}</td>
            </tr>
            <tr>
              <td l10nTranslate>registration.phone</td>
              <td>{{ (company | async)?.phone }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.foundationDate</td>
              <td>{{ (company | async)?.foundationDate | date:'yyyy-MM-dd' }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.seat</td>
              <td>{{ (company | async)?.seat }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.site</td>
              <td>{{ (company | async)?.sites }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.companyId</td>
              <td>{{ (company | async)?.companyId }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.taxNumber</td>
              <td>{{ (company | async)?.taxNumber }}</td>
            </tr>
            <tr>
              <td l10nTranslate>company.companyType</td>
              <td l10nTranslate>{{ (company | async)?.type }}</td>
            </tr>
            <tr>
              <td>
                <a class="btn"
                   [routerLink]="['/companies', (companyId | async), 'qrPlaques']" l10nTranslate *ngIf="(company | async)?.type === 'QR_IMPLEMENTER'" style="width: 70%">employeeList.qrPlauqes</a>
              </td>
              <td>
                <a class="btn" [routerLink]="['/companies', (companyId | async), 'soldCapsules']" l10nTranslate *ngIf="companyEmployees?.length > 0" style="width: 70%">employeeList.showSoldCapsules</a>
              </td>

            </tr>
          </table>

          <h2 class="page_title" l10nTranslate>company.employees
            <span>({{ companyEmployees?.length }})</span></h2>

          <table class="admin_table">
            <tr *ngFor="let item of companyEmployees">
              <td>{{item.name}} ({{item.soldCapsules?.length}} <span
                l10nTranslate>employeeList.capsuleSoldQuantity</span>)
              </td>
              <td *ngIf="canModifyEmployees | async"><input type="button" value="Törlés"
                                                            (click)="onRemoveEmployee(item)"></td>
            </tr>
          </table>
          <div class="input_line" *ngIf="canModifyEmployees | async">
            <input type="text" ngui-auto-complete
                   [formControl]="user"
                   [source]="allEmployees.bind(this)"
                   [list-formatter]="autocompleListFormatter"
                   display-property-name="name"
                   #employeeInput
                   [value-formatter]="myValueFormatter"/>

            <input type="button" l10n-value l10nTranslate value="employeeList.addButton"
                   (click)="onAddEmployee(user.value)">
          </div>
          <!--  <label l10nTranslate>employee.fromDate <span>*</span><input type="date" name="dob"
                                                                          min="1800-01-01"
                                                                          max="2099-12-31"
                                                                          required
                                                                          [(ngModel)]="dto.from"/></label>
            <label l10nTranslate>employee.toDate <span>*</span><input type="date" name="dob"
                                                                          min="1800-01-01"
                                                                          max="2099-12-31"
                                                                          required
                                                                          [(ngModel)]="dto.to"/></label>-->


          <!--<div class="col-xs-12 col-12 col-md-4 form-group">
            <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue">
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

