<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title" l10nTranslate>user.list</h1>
      <div class="white_bg">
        <div class="admin">
          <table class="admin_table">
          <thead>
            <tr>
              <th l10nTranslate>registration.name</th>
              <th l10nTranslate>userList.email</th>
              <th l10nTranslate>registration.phone</th>
              <th l10nTranslate>type</th>
            </tr>
          </thead>
          <tbody>
          <tr name="pager"
              *ngFor="let item of (collect | async)?.content | paginate: { itemsPerPage: (collect | async)?.size, currentPage: page | async, totalItems: (collect | async)?.totalElements}">
            <td>{{item.name}}</td><td><a href="mailto:{{item.email}}">{{item.email}}</a></td><td>{{item.phone}}</td><td>{{item.userType}}</td>
          </tr>
          </tbody>
          <tr>
            <td colspan="2"><pagination-controls #paginationControl
                                                 previousLabel="Előző"
                                                 nextLabel="Következő"></pagination-controls></td>
            <td colspan="2"><label class="admin_user_search" l10nTranslate>search.name <input type="text" #searchText></label></td>
          </tr>
          </table>

<!--<input
  ngui-auto-complete
       [formControl]="user"
       [(ngModel)]="selected"
       [source]="allEmployees.bind(this)"
       [list-formatter]="autocompleListFormatter"
       display-property-name="name"
       [value-formatter]="myValueFormatter"
/> -->
        </div>
      </div>
    </div>
  </div>
</div>
