<div class="wrapper">
    <div class="container">
        <div class="red_bg">
            <h1 class="page_title" l10nTranslate>companyList.createNew</h1>
            <div class="white_bg">
                <div class="admin">


                    <form #f="ngForm" class="form" (submit)="onSubmit()">
                        <div class="half">
                            <label l10nTranslate>company.name<input type="text" name="name" required
                                                                         [(ngModel)]="dto.name"></label>

                            <label l10nTranslate>company.email<input type="email" name="email" required
                                                                          [(ngModel)]="dto.email"></label>

                            <label l10nTranslate>registration.password<input type="password" name="password" required
                                                                             minlength="6"
                                                                             [(ngModel)]="dto.password"></label>

                            <label l10nTranslate>company.phone<input type="text" name="phone" required
                                                                          [(ngModel)]="dto.phone"></label>

                            <label l10nTranslate>company.representative<input type="text" name="representative" required
                                                                     [(ngModel)]="dto.representative"></label>
                            <label l10nTranslate>company.representative.email<input type="email" name="representativeEmail"
                                                                           required
                                                                           [(ngModel)]="dto.representativeEmail"></label>
                        </div>
                        <div class="half">
                    <!--        <label l10nTranslate>company.foundationDate<input type="date" name="foundationDate" required
                                                                              [(ngModel)]="dto.foundationDate"></label>-->

                          <label l10nTranslate>company.foundationDate <span>*</span>
                              <input type="text"
                                     class="form-control"
                                     #dp="bsDatepicker"
                                     [(ngModel)]="dto.foundationDate"
                                     [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                     name="foundationDate"
                                     bsDatepicker>
                          </label>

                            <label l10nTranslate>company.companyId<input type="text" name="companyId" required
                                                                         [(ngModel)]="dto.companyId"></label>

                            <label l10nTranslate>company.taxNumber<input type="text" name="taxNumber" required
                                                                         [(ngModel)]="dto.taxNumber"></label>

                            <label l10nTranslate>company.seat<input type="text" name="seat" required
                                                                    id="autocompleteHomeLocation"
                                                                    ng2-google-place-autocomplete
                                                                    [(ngModel)]="dto.seat"></label>

                            <label l10nTranslate>company.site<input type="text" name="sites" required
                                                                    [(ngModel)]="dto.sites"></label>

                            <label l10nTranslate>
                                company.companyType
                                <select required name="companyType" [(ngModel)]="dto.type">
                                    <option value="FUNERAL_COMPANY" l10nTranslate>FUNERAL_COMPANY</option>
                                    <option value="UMBRELLA_DEALER" l10nTranslate>UMBRELLA_DEALER</option>
                                    <option value="QR_IMPLEMENTER" l10nTranslate>QR_IMPLEMENTER</option>
                                </select>
                            </label>
                        </div>
                        <br class="clear"/>

                        <h2 class="section_title" l10nTranslate>company.managerDetails</h2>
                        <div class="half">
                            <label l10nTranslate>companyManager.name<input type="text" required name="managerName"
                                                                         [(ngModel)]="dto.manager.name"></label>

                            <label l10nTranslate>companyManager.email<input type="email" required name="email"
                                                                          [(ngModel)]="dto.manager.email"></label>

                            <label l10nTranslate>companyManager.phone<input type="text" required name="managerPhone"
                                                                          [(ngModel)]="dto.manager.phone"></label>
                        </div>
                        <div class="half">
                            <label l10nTranslate>companyManager.billingInfo<input type="text" required name="billingInfo"
                                                                           [(ngModel)]="dto.manager.billingInfo"></label>

                            <label l10nTranslate>companyManager.idCard<input type="text" required name="idCard"
                                                                      [(ngModel)]="dto.manager.idCard"></label>

                            <label><input type="submit" name="submit" #submitButton l10n-value l10nTranslate
                                          value="create" [disabled]="!f.valid"></label>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
