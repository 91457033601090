<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <div class="white_bg">
        <div class="admin">
          <label class="admin_user_search" l10nTranslate>search.firstName<input type="text"
                                                                                #searchText></label>

          <label class="admin_user_search" l10nTranslate>search.lastName<input type="text"
                                                                               #searchText2></label>
          <table class="admin_table">
            <thead>
            <tr>
              <th l10nTranslate>registration.name</th>
              <th l10nTranslate>capsule.authorityName</th>
              <th l10nTranslate>capsule.deathNumber</th>
              <th l10nTranslate>capsule.deathCert</th>
              <th l10nTranslate>capsule.dateOfBirth</th>
              <th l10nTranslate>login.emailAddress</th>
              <th l10nTranslate>registration.phone</th>
              <th l10nTranslate>capsule.created</th>
              <th l10nTranslate>capsule.status</th>
              <th l10nTranslate>capsule.suspendedByAt</th>
            </tr>
            </thead>
            <tbody>
            <tr name="pager"
                *ngFor="let item of (capsules | async)?.content | paginate: { itemsPerPage: (capsules | async)?.size, currentPage: page | async, totalItems: (capsules | async)?.totalElements}">
              <td><a class="red_link" [routerLink]="['', { outlets :{modal: ['capsules' , item.id]}}]">{{item.firstName}}
                {{item.lastName}} </a></td>
              <td>{{item.authorityName}}</td>
              <td>{{item.deathNumber}}</td>
              <td><a *ngIf="item.deadCertPath!=null" [href]="item.deadCertPath" target="_blank" l10nTranslate>view</a>
                <span *ngIf="item.deadCertPath==null" l10nTranslate>nocontent</span></td>
              <td>{{item.dob}}</td>
              <td>{{item.ownerEmail}}</td>
              <td>{{item.ownerPhone}}</td>
              <td>{{item.created.split("T",1)}}</td>
              <td>
                <select [(ngModel)]="item.inactive" (change)="statusChanged(item)">
                  <option *ngFor="let status of (capsuleStates)" value="{{status}}" l10nTranslate>inactive.{{ status }}
                  </option>
                </select>
              </td>
              <td>
                  <span *ngIf="item.inactive">{{item.updated | date: 'yyyy-MM-dd'}} ({{item.modifier}})</span>
                  <span *ngIf="!item.inactive">-</span>
              </td>
            </tr>
            </tbody>
            <tr>
              <td colspan="10">
                <pagination-controls #paginationControl
                                     previousLabel="Előző"
                                     nextLabel="Következő"></pagination-controls>
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>
