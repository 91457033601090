<div class="wrapper top_wrapper">
    <header>
        <a id="top-logo" routerLink="/"><img src="/assets/gfx/header/img_inmemori_inner_logo.png"/></a>
        <nav *ngIf="!(simplifiedHeader | async)">
            <ul id="top-menu">
                <li><a [routerLink]="[{ outlets: { primary: 'capsules', modal: null, modal2: null }}]"
                       routerLinkActive="current"
                       l10n-title
                       l10nTranslate
                       title="search.title"><span><i
                        class="fa fa-map-marker"
                        aria-hidden="true"></i></span></a>
                </li>

                <li><a [routerLink]="[{ outlets: { primary: 'placeSearch', modal: null, modal2: null } }]"
                       routerLinkActive="current"
                       l10n-title
                       l10nTranslate
                       title="placeSearch.title"><span><i
                        class="icofont icofont-electron"></i></span></a></li>

                <li [class.disabled]="!(loggedIn | async)"><a
                        [routerLink]="[{ outlets: { primary: 'newCapsule', modal: null, modal2: null } }]"
                        routerLinkActive="current"
                        l10n-title
                        l10nTranslate
                        title="profileMenu.createNewCapsule"><span><i
                        class="icofont icofont-infinite" aria-hidden="true"></i></span></a>
                </li>

                <li [class.disabled]="!(loggedIn | async)"><a
                        [routerLink]="[{ outlets: { primary: 'myCapsules', modal: null, modal2: null } }]"
                        routerLinkActive="current"
                        l10n-title
                        l10nTranslate
                        title="profileMenu.myCapsules"><span><i class="icofont icofont-users-alt-4"
                                                                aria-hidden="true"></i></span></a>
                </li>

                <li [class.disabled]="!(loggedIn | async)"><a
                        [routerLink]="[{ outlets: { primary: 'favourites', modal: null, modal2: null } }]"
                        routerLinkActive="current"
                        title="favorites.title"
                        l10nTranslate
                        l10n-title><span><i
                        class="icofont icofont-users-social"
                        aria-hidden="true"></i></span></a>
                </li>

                <li [class.disabled]="!(loggedIn | async)"><a
                        [routerLink]="['', { outlets: { modal: 'sympathies', modal2: null } }]"
                        routerLinkActive="current"
                        l10nTranslate
                        l10n-title
                        title="sympathyMessages.title"><span><i
                        class="fa fa-comments-o"
                        aria-hidden="true"></i><span
                        class="counter">{{ pendingMessagesCount | async }}</span></span></a>
                </li>

                <li [class.disabled]="!(loggedIn | async)"><a
                        routerLinkActive="current"
                        [routerLink]="['', { outlets: { modal: 'messagesByMe', modal2: null } }]"
                        title="myMessages.title"
                        l10nTranslate
                        l10n-title><span><i
                        class="icofont icofont-social-wechat"
                        aria-hidden="true"></i></span></a>
                </li>
<!--
                <li><a
                        [routerLink]="[{ outlets: { primary: 'community', modal: null, modal2: null } }]"
                        fragment="community-about-sympaty"
                        routerLinkActive="current"
                        [routerLinkActiveOptions]="{exact: true}"
                        title="aboutSympaty.title"
                        l10nTranslate
                        l10n-title><span><i
                        class="fa fa-commenting"
                        aria-hidden="true"></i></span></a>
                </li>

                <li><a
                        [routerLink]="[{ outlets: { primary: 'community', modal: null, modal2: null } }]"
                        fragment="community-about-qr"
                        routerLinkActive="current"
                        [routerLinkActiveOptions]="{exact: true}"
                        title="aboutQrPlaque.title"
                        l10nTranslate
                        l10n-title><span><i
                        class="fa fa-qrcode"
                        aria-hidden="true"></i></span></a>
                </li>
-->

                <!-- <li *ngIf="userService.isLoggedIn | async"><a [routerLink]="['', { outlets: { modal: 'myMessages' } }]"
                                                               routerLinkActive="current"
                                                               l10nTranslate
                                                               l10n-title
                                                               title="sympathyMessages.title"><span><i
                   class="fa fa-comment-o"
                   aria-hidden="true"></i><span
                   class="counter">{{ pendingMessagesCount | async }}</span></span></a>
                 </li>
               -->

                <li class="hidden" routerLinkActive="current"><a
                        [routerLink]=" ['', { outlets: { 'modal': ['capsules'], modal2: null } }] "
                        routerLinkActive="current"><span><i
                        class="icofont icofont-users-alt-4"></i></span></a></li>


            </ul>
        </nav>
        <app-profile-menu [simplifiedHeader]="simplifiedHeader"></app-profile-menu>
    </header>
</div>
