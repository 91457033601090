<div class="wrapper gray_gradient" id="page-two">
    <div class="home_tabs">
        <div class="half centered">
            <img src="/assets/gfx/design/img_connections.png"/>
        </div>
        <div class="half">
            <div class="tabs_holder">
                <ngb-tabset #tabset (tabChange)="tabChange()">
                    <ngb-tab id="1">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps?</h2>
                            <p align="justify"><strong>Az InmemorI Virtual Time Caps segítségével a virtuális térben emlékezhetünk elhunyt szeretteinkre, ahol megőrizhetjük emléküket a jövő, a felnövekvő generáció számára is.</strong></p>
                            <p align="justify">A mai felgyorsult világban az élettől elbúcsúzott szeretteink részére alakítunk méltó  emlékhelyet. Az InmemorI Virtual Time Caps  egyben egy virtuális kegyeleti hely, mely a szép emlékeknek ad méltó felületet. Lehetőséget biztosít arra, hogy örök nyomot hagyjunk az elhunyt életében történtekről. Nem a gyászt hosszabbítjuk meg, hanem tisztelgünk az elhunyt emléke előtt!</p>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="2">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps tartalma</h2>
                            <p align="justify"><strong>Az InmemorI Virtual Time Caps bemutatja szerettünk életpályáját, sikereit, elismeréseit, díjait. Tizenkét fotóval és négy szöveges fájllal prezentálhatjuk életét azok számára is, akik más-más életkorban ismerték.</strong></p>
                            <p align="justify">Az InmemorI Virtual Time Caps célja, hogy szerettünkről nyomot hagyjon az utókor számára.
                            </p>
                        </ng-template>
                    </ngb-tab>


                    <ngb-tab id="3">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps “SYMPATY”</h2>
                            <p align="justify"><strong>Sympaty küldésével tisztelgünk az elhunyt előtt, amellyel megköszönjük az életét, azt hogy a mi életünk alakítója is volt, és egyben a kommunikációnkkal, közös fotóinkkal, emlékeinkkel tisztelgünk szerettünk előtt.</strong></p>
                            <p align="justify">A “Sympaty” a materiális fogalmak szerint egy kegyelet nyilvánítás, mely felváltja az elhervadó virágokat és koszorúkat, és egyben a capsulára feltöltött élményeket is bővíti. A Sympatyval “részvétet” kívánhatunk akár a búcsúztató (temetés) során is. A “Sympaty”-k az adott Inemmori Virtual Time Capsban publikusan csak a gondozó jóváhagyásával jelennek meg (kegyeletsértés kizárása okából)!</p>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="4">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps “Celebrity”</h2>
                            <p align="justify"><strong>Ismert közéleti személyiségek, hírességek, elöljárók InmemorI Virtual Time Caps-a.</strong></p>
                            <p align="justify">InmemorI Virtual Time Caps “Celebrity” biztosítja a kontinensek, államok, közösségek, egyházak elöljáróinak, saját hírességeinek, elhunytjainak emlékhelyét, akiket az adott közösség saját halottjának tekint. A capsulát a közösség vezetősége hozza létre.</p>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="5">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps “Keresés”</h2>
                            <p align="justify"><strong>Találja meg szeretteit, barátait, elöljáróit az InmemorI Virtual Time Caps “közeledben” vagy “hely alapú” keresés segítségével!</strong></p>
                            <p align="justify">Kereshet név, születési idő, elhalálozási idő, hely alapján, vagy akár testvér azonosításával is. Az InmemorI megmutatja az Ön közelében lévő caps-t, és egyben a világon minden városra, helységre rákereshet térképek segítségével is.</p>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="6">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps “Vásárlás/ Létrehozás”</h2>
                            <p align="justify"><strong>Itt a lehetőség, hogy most Ön hozzon létre szerettei részére  InmemorI Virtual Time Caps-t!</strong></p>
                            <p align="justify">Regisztráljon most valós adataival (ingyenesen, ha még nem regisztrált), majd készítse elő a szerette halotti anyakönyvi kivonatát (minden elhunytnak a világon csak egy InmemorI Virtual Time Caps-a lehet!), adatait, 12 más-más életkorban lévő fotóit, esetleges kitüntetéseit, emlék dokumentumait, és írja meg az életpályáját. Helyezze emlékét örökre biztonságba az utókor számára (Rendszerünk erősen figyeli a kegyeletsértési kezdeményezést, és azonnal felfüggeszti az esetleges próbálkozásokat).</p>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab id="7">
                        <ng-template ngbTabContent>
                            <h2>InmemorI Virtual Time Caps “QR Plakett”</h2>
                            <p align="justify"><strong>InmemorI Virtual Time Caps létrehozásakor minden capsulához egy QR kód generálódik, mely utal a létrehozott caps-a linkjére. Az InmemorI létrehozta a virtuális temetőt is a “QR kód Plakett” segítségével.</strong></p>
                            <p align="justify">Opcionálisan a caps-hoz vásárolható InmemorI “QR Plakett”, melyet az InmemorI World Kft. legyárt, 240 órán belül a capsula gondozója részére postázza. A gondozó a valós sírkertben a fejfára helyezheti. Az InmemorI UV álló réteggel bevont QR kód (gravírozott) Plakett a fejfán, vagy az emléktáblán elhelyezve a látogató részére azonnal betekintést nyújt az elhunyt virtuális emlékhelyébe, okos eszköze, telefonja segítségével.</p>
                        </ng-template>
                    </ngb-tab>

                </ngb-tabset>
            </div>
        </div>
    </div>
</div>





