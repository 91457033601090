<div class="wrapper">
  <div class="container">
    <div class="red_bg">
      <h1 class="page_title" l10nTranslate>company.list</h1>
      <div class="white_bg">
        <span l10nTranslate>companyList.searchHelp</span>
      </div>
      <div class="white_bg">
        <div class="admin">
          <div class="firm_list">
            <ul>
              <span l10nTranslate>FUNERAL_COMPANIES</span>
              <li *ngFor="let item of (partnerList | async)">
                <a [routerLink]="['/companies', item.id]">{{item.name}}</a>
              </li>
            </ul>
          </div>

          <div class="firm_list">
            <ul>
              <span l10nTranslate>UMBRELLA_DEALER_COMPANY</span>
              <li *ngFor="let item of (umbrellaDealerList | async)">
                <a [routerLink]="['/companies', item.id]">{{item.name}}</a>
              </li>
            </ul>
          </div>

          <div class="firm_list">
            <ul>
              <span l10nTranslate>QR_IMPLEMENTER_COMPANIES</span>
              <li *ngFor="let item of (qrMakerList | async)">
                <a [routerLink]="['/companies', item.id]">{{item.name}}</a>
              </li>
            </ul>
          </div>
          <a class="btn" routerLink="/admin/companies/new" l10nTranslate>companyList.createNew</a>
        </div>

      </div>
    </div>
  </div>
</div>
