<contentSlider #slider [autoPlay]="1" [slides]="images"></contentSlider  >
<div>
    <div *ngIf="slider.getCurrentElement() != 0" class="welcomeMessage">
        <h2  innerHTML="home.welcomeMessage" l10n-innerHTML l10nTranslate></h2>
        <a class="btn_more" l10nTranslate (click)="onBrowsingClick()">home.browseWithoutLogin</a>
    </div>
  <div class="arrow_down">
    <a id="home-arrow-down" href="javascript:void(0)" (click)="scrollAnchor.scrollIntoView()"><i
            class="fa fa-angle-down" aria-hidden="true"></i></a>
  </div>
</div>
<div style="position:relative;">
    <div id="home-scroll-anchor" #scrollAnchor></div>
</div>
<app-tabs></app-tabs>

<div class="wrapper about_us_wrapper">
    <div class="container">
        <div class="about_us">
            <div class="half">
                <div class="html_edited">
                    <h2 l10nTranslate>home.leftTitle</h2>
                    <p innerHTML="home.leftDescriptionShort" l10nTranslate l10n-innerHTML></p>
                    <a class="btn_more" l10nTranslate (click)="onBrowsingClick()">home.browseWithoutLogin</a>
                </div>
            </div>
            <div class="half">
                <div class="html_edited">
                    <h2 l10nTranslate>home.rightTitle</h2>
                    <p innerHTML="home.rightDescription" l10nTranslate l10n-innerHTML></p>
                    <a [routerLink]="['/register']" class="btn_more white" l10nTranslate>home.registration.title</a>
                </div>
            </div>
        </div>
    </div>
</div>



