<div [ngClass]="(simplifiedHeader | async) ? '' : !(userService.isLoggedIn | async) ? 'notloggedin' : 'loggedin'">
    <ng-progress [color]="'white'"></ng-progress>
    <div class="mobile_menu">
        <cuppa-slidemenu #sliderMenu [menulist]="menuItemsArray"
                         (onItemSelect)="onMenuItemSelect($event)"></cuppa-slidemenu>
        <a id="top-logo-mobile" routerLink="/"><img src="/assets/gfx/header/img_inmemori_inner_logo.png"/></a>
        <app-profile-menu [simplifiedHeader]="simplifiedHeader"></app-profile-menu>
    </div>
    <app-headermenu [simplifiedHeader]="simplifiedHeader"></app-headermenu>
    <router-outlet></router-outlet>
    <router-outlet name="modal"></router-outlet>
    <router-outlet name="modal2"></router-outlet>
</div>
<app-footer></app-footer>
