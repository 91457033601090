<div class="wrapper minheight">
  <div class="container">
    <div class="title_line">
        <h1 class="page_title" l10nTranslate>profileMenu.myCapsules</h1>
    </div>

    <div class=" result_content">
      <div class="capsule_list">
        <div *ngFor="let item of (capsuleService.userCapsules | async)" class="capsule_item">
          <div class="capsule">
            <app-capsule [capsule]="item"></app-capsule>
          </div>
        </div>
      </div>

      <app-error-message [message]="'noItemFound'" *ngIf="(capsuleService.userCapsules | async)?.length == 0"></app-error-message>
    </div>
  </div>
</div>

